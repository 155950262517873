import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const PointList = ({ dataList }) => {
    return (
        <div className='pointList'>
            <Row>
                {
                    dataList.map((item, index) => {
                        const date = new Date(item?.KayitTarihi);
                        return (
                            <Col id={`${item?.ID}_${index}`} className='pointBox' xs={12} sm={12} md={4} >
                                <Card style={{ width: "100%" }}>
                                    <Card.Body>
                                        <h5>
                                            Tarih : <strong>{`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`}</strong>
                                        </h5>
                                        <hr></hr>
                                        <p>
                                            Kupon No : <strong>{item?.KuponNo}</strong>
                                        </p>
                                        <p>
                                            Puan : <strong>{item?.Puan}</strong>
                                        </p>
                                        <p>
                                            Durum : <strong>{item?.Status ? "Aktif" : "Pasif"}</strong>
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }

            </Row>
        </div>
    );
}

export default PointList;
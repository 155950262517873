import React, { useEffect, useState} from 'react';
import { Tabs, Tab, Container, Row, Button, Col } from 'react-bootstrap';
import AddressList from './Address';
import ChangePassword from './changePassword';
import OrderList from './orderList';
import PersonalInformation from './personalinformations';
import { useHistory } from 'react-router';
import { getUserInfo } from '../../api/service/loginServices';
import ReactGA from 'react-ga4';
import Points from './Points';

function User() {
    const [key, setKey] = useState('kisisel-bilgilerim');
    const [userInfo, setUserInfo] = useState(null);

    const history = useHistory();

    useEffect(() => {
       //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title:"Hesabım" });
        document.body.classList.add('accounts');
    });

    useEffect(() => {
        return () => {
            document.body.classList.remove('accounts');
        };
    }, []);

    useEffect(() => {
        const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
        const activeTab = window.location.href.split('?')[1]
        if (userInfo === null) {
            if (activeTab !== undefined) {
                setKey(activeTab);
            }
            getUser();
        }
        if (!loggedIn) {
            history.push('/account/login')
        }
    }, [userInfo, history]);

    const handleLogOut = () => {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('access_token');
        history.push('/');

    }
    const getUser = async () => {
        const res = await getUserInfo();
        if (!res.errStatus) {
            setUserInfo(res.data[0]);
        }
    }
    const handleTabsChange = (k) => {
        setKey(k);
    }

    const handleAddressList = async () => await getUser();

    return (
        <>
            <Container fluid className="account-full-part">
                <Row>
                    <Container className="account-normal-part">
                        <Row>
                            <Col md={12} className="account-left-box">
                                <div className="user-info-grid-part">
                                    <h3>Merhaba,</h3>
                                    <h3>{userInfo !== null ? userInfo.Ad + " " + userInfo.Soyad : ''}</h3>
                                    <p>Daha fazla bilgiyi değiştirebilir ve ekleyebilirsiniz</p>
                                    <Button onClick={handleLogOut}>Çıkış Yap</Button>
                                </div>
                                <Tabs defaultActiveKey="kisisel-bilgilerim" id="uncontrolled-tab-example" className={"mb-3 contact-tabs-box " + key}
                                    onSelect={(k) => handleTabsChange(k)}
                                    activeKey={key}
                                >

                                    <Tab eventKey="kisisel-bilgilerim" title="Kişisel Bilgilerim"  >
                                        <PersonalInformation userInfo={userInfo} />
                                    </Tab>
                                    <Tab eventKey="sifre-degistir" title="Şifremi Değiştir">
                                        <ChangePassword userInfo={userInfo} />
                                    </Tab>
                                    <Tab eventKey="siparislerim" title="Siparişlerim">
                                        <OrderList />
                                    </Tab>
                                    {/* <Tab eventKey="favorilerim" title="Favorilerim">
                                        <Favorites />
                                    </Tab> */}
                                    <Tab eventKey="adreslist" title="Adreslerim">
                                        <AddressList handleAddressList={handleAddressList} />
                                    </Tab>
                                    <Tab eventKey="pointList" title="Puanlarım">
                                        <Points />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );
}
export default User;


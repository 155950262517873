import React ,{useState,useRef, useEffect} from 'react';
import { Popover,Button,OverlayTrigger } from 'react-bootstrap';
import removeIcon from '../../images/remove.svg';
import { Link , withRouter } from 'react-router-dom';
import { productBaseUrl, productBaseUrl2} from '../../constans';
import { Dec, Inc } from '../../constans/svgExport';
import {searchCartCampaignCheck} from '../../api/service/cartServices';

const PopoverCart = (props) =>  {
  const [cartCapaignProduct, setCartCapaignProduct] = useState([]);
  const [popoverShow, setPopoverShow] = useState(false)
  const target = useRef(null);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('state'));
    if(cart !== null){
      if(cart.length > 0){
        const filterCart = cart.filter((e) => e.qty > 0);
        if(cart.length !== filterCart.length){
          localStorage.setItem('state',JSON.stringify(filterCart));
          window.location.reload();
        }
      }
    }
  },[])

  const getCampaignCheck = async (data) => {
    
      const res = await searchCartCampaignCheck(data);
      const cart = JSON.parse(localStorage.getItem('state'));
      const cartList = [];
      if(!res.errStatus) {
          cartList.push(cart);
          cartList.push(res.data);
          setCartCapaignProduct(cartList);
          setPopoverShow(true);

      }
    

}

  const handleCampaingCheck = async (e) => {
      // let items = 0;
      let price = 0;
      const urunInfo = [];
      const cartData = {};
      if(props.cart.length === 0){
          const cart = JSON.parse(localStorage.getItem('state'));
          if(cart !== null) {
            cart.forEach((item) => {
              // items += item.qty;
              price += item.qty * item.Price;
              urunInfo.push({UrunID: item.ProductId, adet: item.Unit === "Kg" ? item.qty/4 : item.qty});

          });
          }
      
      }
      else {
          props.cart.forEach((item) => {
              // items += item.qty;
              price += item.qty * item.Price;
              urunInfo.push({UrunID: item.ProductId, adet: item.Unit === "Kg" ? item.qty/4 : item.qty});
          });
      }
      if(urunInfo.length > 0){
          cartData.urunInfo = urunInfo;
          cartData.sepetToplam = price;
          if(cartCapaignProduct.length > 1) {
              if(cartCapaignProduct[1].sepetToplam !== cartData.sepetToplam.toFixed(2)) {
                await getCampaignCheck(cartData);
              }
              else {
                  setPopoverShow(true);

              }
          }
          else{
            await getCampaignCheck(cartData);
          }
        
        }

  }
  
  const productTotalPrice = (arr) => {
    if(arr !== undefined &&  arr !== null && arr !== "")
      if(arr.length > 0) {
        const reducer = (previousValue, currentValue) => previousValue + currentValue;
        return (arr.reduce(reducer));

      }
    return '';
  }

  const handleRemoveCartItem = async (id) => {
    await handleCampaingCheck(id);
    props.removeItemToCart(id);


  }
 
  const handleAddtoCartItem = async (id) => {
    await handleCampaingCheck(id);
    props.addToCart(id);

  }
 
  const popover = (
        <Popover id="popover-basic"  onMouseLeave={()=> setPopoverShow(false)} >
        <Popover.Header as="h3">Sepetiniz</Popover.Header>
        <Popover.Body   >      
          <div className="cart-product-list-box" >
              {props.cart ? <>
                  {props.cart.map(product => {
                    return(
                          <div className="cart-product-box" key={product.ProductId}>
                              <div className="product-image-box">
                                <img src={productBaseUrl2+product.ImageUrl} alt="sucuk" />
                              </div>
                              <div className="produt-desc-price-box"> 
                                  <h6>{product.ProductName}</h6>
                                  <span>{product.qty * product.Piece} {product.Unit}</span>
                                  <span> {(product.Unit === "Kg" ? (product.qty * product.Piece * product.Price * 4) : (product.qty * product.Piece * product.Price))} TL</span>
                              </div>
                              <div className="product-unit-dec">
                              <Button onClick={() => props.removeToCart(product.ProductId)}>
                                    <img src={removeIcon} alt="remove-button" />
                                </Button>
                                <div className="numbers-row">
                                    <Button className="dec"  onClick={() => handleRemoveCartItem(product.ProductId)}>
                                            {Dec()}
                                    </Button>
                                    <span> <span>{product.Unit === "Kg" ? (product.qty/4) : product.qty } </span><span> ({product.Unit})</span> </span>
                                    <Button className="inc" onClick={() => handleAddtoCartItem(product.ProductId)}>
                                        {Inc()}
                                    </Button>
                                </div>
                              </div>
                            </div>
                    );
                  })}
                  </>
                :
                <>
                    <div>Henüz sepete ürün eklemediniz.</div>
                </>
              }
          </div>
          {cartCapaignProduct.length > 0 ? <>
            <div className="popover-ara-toplam"> 
                    <span>Ara Toplam:</span>
                    <span>{productTotalPrice(props.cart.map((e) => e.Unit === "Kg" ? e.qty * e.Price * e.Piece * 4 : e.qty * e.Price * e.Piece))} TL</span> </div> 
             
            <div className="cargo-and-discount-box">
              <span className="span-box">
                  <span>Kargo</span>
                  {cartCapaignProduct[1].kargoTutari === 0 ? <span className="cargo-bedava">(Bedava)</span> : <span> {cartCapaignProduct[1].kargoTutari } TL </span>}
              </span>
              <span className="cargo-mesaj">{cartCapaignProduct[1].kargoMesaj}</span>
              {cartCapaignProduct[1].kargoTutari === 0 || cartCapaignProduct[1].kargoTutari < 45 ?
              <span className="span-box">
                 {cartCapaignProduct[1].indirimMiktari !== 0 ? <>
                  <span>İndirim</span>
                  <span>- {cartCapaignProduct[1].indirimMiktari?.toFixed(2) } TL</span> </>: ''}
              </span> : '' }
              </div> 
              <div className="cart-button-box">
                  <span>
                  <span>Toplam</span>
                  <span > {cartCapaignProduct[1].sepetToplam?.toFixed(2)} TL</span>
                  </span>
                  {/* <p>4.5 TL’lik alışveriş yaparsanız kargo bedava.</p> */}
                  <div className="button-boc">
                  <Link to="/cart/index">
                      Sepete Git
                  </Link>
                  <Link to="/order/checkout">
                      Satın Al
                  </Link>
                  </div>
              
              </div>
        </>: ''}
        </Popover.Body>
      </Popover> 

  );

  if(props.cart.length > 0 ){
    return(
      <div onMouseLeave={()=> setPopoverShow(false)}>
        <OverlayTrigger trigger={["hover","focus"]}  show={popoverShow} onToggle={async (e) => await handleCampaingCheck(e)} target={target.current} placement="bottom-end" overlay={popover}>
          <Link to="/cart/index" >
            <Button variant="success">Sepetim</Button></Link>
        </OverlayTrigger>
    </div>
    );
  }
  else {
      return(
      <>
          <Link to="/cart/index" >
            <Button variant="success">Sepetim</Button> 
          </Link>
      </>)
  }
  
}

  export default withRouter(PopoverCart);

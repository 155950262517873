import React, {useState, useRef} from 'react';
import { Form ,Button,Modal,Alert, Row,Col} from 'react-bootstrap';
import KampanyaMetni from '../../views/Policy/kampanyaMetni';
import { Formik } from 'formik';
import {createUser} from '../../api/service/loginServices';
import {useHistory} from 'react-router-dom';
import Kvkk from '../../views/Policy/kvkk';
import { sginupValitadion } from '../../constans';

function SginUp() {
    const [show, setShow] = useState(false);
    const [showKvkk, setShowKvkk] = useState(false);
    const [showAlert, setShowAlert] = useState({
        show:false,
        message:'',
        variant:'danger'
    });
    const form = useRef(null);
    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseKvkk = () => setShowKvkk(false);
    const handleShowKvkk = () => setShowKvkk(true);

    const handleSubmit = async (event) => {       
        const res = await createUser(event);
        if(!res.errStatus) {
            if(!res.data.status) {
                setShowAlert({show:true, variant:'danger', message:res.data.mesaj});
            }
            else {
                setShowAlert({show:true,variant:'success', message: 'Kayıt oldunuz lütfen giriş yapınız.'});
                setTimeout(() => {
                    history.push('/account/successuser');

                }, 2000);
            }
        }
        else {
            setShowAlert({show:true, variant:'danger', message: 'Kayıt başarısız lütfen bilgilerinizi kontrol ediniz'});
        }
    };

    return(
        <>
           <Alert className="login-failed-alert"  variant={"danger"}  onClose={() => setShowAlert(false)} dismissible show={showAlert.show}>
                <p>{showAlert.message}</p>

            </Alert>
            <Modal
                className="cantrat-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                <Modal.Title>724 TİCARİ ELEKTRONİK İLETİ ONAY METNİ VE AÇIK RIZA METNİ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <KampanyaMetni />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleClose}>
                    Onaylıyorum
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showKvkk}
                onHide={handleCloseKvkk}
                backdrop="static"
                keyboard={false}
                className="cantrat-modal"
                >
                <Modal.Header closeButton>
                <Modal.Title>724 TİCARİ ELEKTRONİK İLETİ ONAY METNİ VE AÇIK RIZA METNİ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Kvkk />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" onClick={handleCloseKvkk}>
                    Onaylıyorum
                </Button>
                </Modal.Footer>
            </Modal>
                <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    name: '',
                    surname: '',
                    email:'',
                    password:'',
                    Bildirimler:false
                }}
                validationSchema={sginupValitadion}
                >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                }) => (
                <Form ref={form} noValidate onSubmit={handleSubmit}>
                    <Row className="mb-12 login-input-box">
                        <Form.Group as={Col} md="12" controlId="username" className="name-input">
                            <Form.Control
                                type="text" placeholder="Adınız"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isValid={touched.firstName && !errors.firstName}
                            />
                            {touched.name && errors.name ?
                            <div type="invalid" className="validation-error">
                                {errors.name}
                            </div> : ''}
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="password" className="surname-input">
                                <Form.Control
                                   type="text" placeholder="Soyadınız" 
                                    name="surname"
                                    value={values.surname}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                />
                                {touched.surname && errors.surname ?
                                    <div type="invalid" className="validation-error">
                                        {errors.surname}
                                    </div> : ''}
                        </Form.Group>
                            <Form.Group as={Col} md="12" controlId="password" className="email-input">
                                <Form.Control
                                    type="email" placeholder="E posta adresiniz"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                />
                                  {touched.email && errors.email ?
                                    <div type="invalid" className="validation-error">
                                        {errors.email}
                                    </div> : ''}
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="password" className="password-input">
                              <Form.Control
                                    type="password" placeholder="Şifre"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                /> 
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="sginup-camps" className="campaign-input">
                            <Form.Check type="checkbox"  defaultChecked={true}  value={values.Bildirimler} onChange={handleChange} />
                                <Button variant="primary" className="sginup-camps" onClick={handleShow}>
                                    <span>Kampanyalardan ve duyurulardan </span> haberdar olmak istiyorum.
                                </Button>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="sginup-camps" className="campaign-input">
                            <Form.Check type="checkbox" defaultChecked={true} value={values.Bildirimler} onChange={handleChange} />
                                <Button variant="primary" className="sginup-camps" onClick={handleShowKvkk}>
                                    <span>Kişisel verilerin korunması ve aydınlatma metnini</span> okudum onaylıyorum.
                                </Button>
                            </Form.Group>
                        </Row>
                    
                        <Button variant="primary" type="submit">
                                Üye Ol
                        </Button>
                    </Form>
                )}
                </Formik>
                {/* <span className="social-medya-login">Sosyal Hesap İle Giriş</span> */}

        </>
    );
}

export default SginUp;
import React, {useState,useRef,useEffect} from 'react';
import {createAddress, searchByDistrict,searchCityList} from '../../../api/service/loginServices';
import { Button,Form,Row,Col,Toast} from 'react-bootstrap';
import { Formik ,Field} from 'formik';
import InputMask from 'react-input-mask';
import { AddressValidation, slugify } from '../../../constans';

function NewAddress(props) {
        const form = useRef(null);

        const [showMessage, setShowMessage] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [coporateAddress, setCorporateAddress] = useState(false);
        const [cityList, setCityList] = useState([]);
        const [selectedCity, setSelectedCity] = useState();
        const [selectedDistrict, setSelectedDistrict] = useState();
        const [distirictList, setDistirictList] = useState([]);

        useEffect(() => {  
        document.body.classList.add('account-new-address')
       
            if(cityList.length === 0) {
                getCityList();

            }
        
        });
    
        useEffect(() => {
            return () => {
                document.body.classList.remove('account-new-address')
                document.body.classList.remove('uyeliksiz');
            };
        }, []);
        const getCityList = async () => {
            const res = await searchCityList();
            if(!res.errStatus) {
                res.data = res.data.sort(function (a, b) {
                        return a.Sehir.localeCompare(b.Sehir); //using String.prototype.localCompare()
                    });
                setCityList(res.data);
            }
        }

    


        const handleCityChange = async (e) => {
            setSelectedCity(e.target.value);
            const distiricts = await searchByDistrict(e.target.value);
            if(!distiricts.errStatus) {
                setDistirictList(distiricts.data);
            }
        }

        const handleDistrictChange = (e) => {
            setSelectedDistrict(e.target.value);
        }

        const handleSubmit = async (event) => {
            event.Sehir = selectedCity;
            event.Ilce = selectedDistrict;
            if(event.Adres ==="") {
                setErrorMessage("Zorunlu alanları doldurunuz.")
            }
            else {
                const addressData = {};
                addressData.AdSoyad = event.AdSoyad;
                addressData.Adres = event.Adres;
                addressData.AdresAdi = event.AdresAdi;
                addressData.Sehir = parseInt(selectedCity);
                addressData.Ilce = parseInt(selectedDistrict);
                addressData.CepTel = slugify(event.CepTel).replaceAll('-','');
                addressData.TcNo = event.TcNo;
                addressData.VergiNo = event.VergiNo;
                addressData.VergiDaire = event.VergiDaire
                if(event.ayniAdresMi){
                    addressData.Tip = 0;
                    const addressCreate = await createAddress(addressData);
                    if(!addressCreate.errStatus) {
                        props.handleFormSubmit();
                    }
                }
            }
        };

        const handleIndividualAddress = () => {
            setCorporateAddress(false);
        }
        const handleCorporateAddress = () => {
            if(!coporateAddress) {
                setCorporateAddress(true);
            }
            else {

                setCorporateAddress(false);
            }

        }

    return(
        <>
         
            <Toast onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                <Toast.Header>
                    <strong className="mr-auto">Şahin724</strong>
                    <small>Uyarı Mesajı</small>
                </Toast.Header>
                <Toast.Body>{errorMessage}</Toast.Body>
            </Toast>
                <label className="form-title">01 Teslim alacak kişinin bilgileri</label>
                <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            AdSoyad:'',
                            Adres: '',
                            AdresAdi:'',
                            CepTel:'',
                            Sehir: '',
                            Ilce: '',
                            TcNo:  '',
                            ayniAdresMi: true,
                            adresTipi: coporateAddress,
                            VergiDairesi: '',
                            VergiNo: 0 
                        }}
                        validationSchema={AddressValidation}
                        >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                        }) => (
                        <Form ref={form}  onSubmit={handleSubmit}>
                            <Row className="mb-12 address-edit-input-box">
                                <Form.Group as={Col} md="12" controlId="name" className="address-edit-input">
                                    <Form.Control
                                            type="text" placeholder="* Ad Soyad"
                                            name="AdSoyad"
                                            value={values.AdSoyad}
                                            onChange={handleChange}
                                            isValid={touched.AdSoyad && !errors.AdSoyad}
                                            
                                        /> 
                                    {touched.AdSoyad && errors.AdSoyad ?
                                    <div type="invalid" className="validation-error">
                                        {errors.AdSoyad}
                                    </div> : ''}
                            </Form.Group>                                
                                <Form.Group as={Col} md="12" controlId="phonenumber" className="address-edit-input ceptel">
                                        <InputMask   style={{padding:'10px'}} name="CepTel" placeholder="* Telefon numaranız"
                                         mask={window.screen.width < 478 ?"09999999999" :"0 (999) 999 99-99"}
                                            onChange={handleChange}
                                            value={values.CepTel}
                                            isValid={touched.CepTel && !errors.CepTel}
                                        />
                                          {touched.CepTel && errors.CepTel ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.CepTel}
                                                </div> : ''}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="tcNo" className="address-edit-input">
                                    <InputMask pattern="[0-9]*" style={{padding:'10px'}} name="TcNo" placeholder="TC kimlik numaranızı giriniz" mask="99999999999"
                                        onChange={handleChange}
                                        isValid={touched.TcNo && !errors.TcNo}
                                    />
                                          {touched.TcNo && errors.TcNo ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.TcNo}
                                                </div> : ''}
                                </Form.Group>
                                <label className="form-title">02 Adres Bilgileriniz</label>
                                <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                <Form.Control
                                        type="text" placeholder="* Örnek: Evim, İşyerim vb"
                                        name="AdresAdi"
                                        value={values.AdresAdi}
                                        onChange={handleChange}
                                        isValid={touched.AdresAdi && !errors.AdresAdi}
                                    /> 
                                        {touched.AdresAdi && errors.AdresAdi ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.AdresAdi}
                                                </div> : ''}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="city" className="address-edit-input">
                                        <Field name="Sehir" component="select" value={selectedCity}
                                            onChange={handleCityChange}
                                            isValid={touched.Sehir && !errors.Sehir}
                                            >
                                            <option >* İl Seçini</option>
                                                {
                                                cityList.map((city) => {
                                                        return(<option key={city.ID} value={city.ID}>{city.Sehir}</option>);

                                                    })
                                                }
                                        </Field>
                                        {touched.Sehir && errors.Sehir ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.Sehir}
                                                </div> : ''}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="distirc" className="address-edit-input">
                                    <Field name="Ilce" component="select" value={selectedDistrict}
                                            onChange={handleDistrictChange}
                                            isValid={touched.Ilce && !errors.Ilce}
                                            >
                                            <option>* İlçe Seçiniz</option>
                                            {
                                                distirictList.length > 0 ?
                                                    distirictList.map((distirict) => {
                                                        return(<option  key={distirict.Id} value={distirict.Id}>{distirict.Ilce}</option>);

                                                }) : ""
                                            }
                                        </Field>
                                        {touched.Ilce && errors.Ilce ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.Ilce}
                                                </div> : ''}
                                </Form.Group>    
                                <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input">
                                        <Form.Control
                                            as="textarea"
                                            rows="2"
                                            placeholder="* Adres giriniz"
                                            name="Adres"
                                            value={values.Adres}
                                            onChange={handleChange}
                                            isValid={touched.Adres && !errors.Adres}
                                            /> 
                                               {touched.Adres && errors.Adres ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.Adres}
                                                </div> : ''}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input-addrescheckbox">
                                    <Form.Check type="checkbox" id="ayni-address-mi" name="oneAddress">
                                            <Form.Check.Input name="ayniAdresMi" 
                                            type="checkbox" defaultChecked="true" 
                                            isValid={touched.ayniAdresMi && !errors.ayniAdresMi}
                                            onChange={handleChange} />
                                            <div className="checkbox-desc">
                                                <Form.Check.Label >Teslimat Adresini Fatura Adresi Olarak Kullan.</Form.Check.Label>
                                                <label>Fatura adresiniz teslimat adresinizden farklı ise fatura bilgilerinizi girmek için aşağıdaki
                                                </label>
                                                <label>“Yeni Fatura Adresi Ekle” butonunu tıklayarak Fatura Bilgilerinizi kayıt ediniz.</label>
                                            
                                            </div>
                                            </Form.Check>
                                            {touched.ayniAdresMi && errors.ayniAdresMi ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.ayniAdresMi}
                                                </div> : ''}
                                </Form.Group>
                                <label className="form-title">03 Fatura Tipi</label>
                                    <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input-checkbox">
                                        <Form.Check type="checkbox" id="bireysel-address" name="adrestip" >
                                                <Form.Check.Input type="radio"  
                                                name="adrestip" 
                                                isValid={touched.adrestip && !errors.adrestip} 
                                                onChange={handleIndividualAddress} />
                                                <Form.Check.Label htmlFor="bireysel-address">* Bireysel</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type="checkbox" id="kurumsal-address" name="adrestip" >
                                                <Form.Check.Input type="radio" name="adrestip" 
                                                isValid={touched.adrestip && !errors.adrestip} 
                                                onChange={handleCorporateAddress}/>
                                                <Form.Check.Label htmlFor="kurumsal-address">* Kurumsal</Form.Check.Label>
                                            </Form.Check>
                                            {touched.adrestip && errors.adrestip ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.adrestip}
                                                </div> : ''}
                                    </Form.Group>
                                    
                                        <div style={{display:`${coporateAddress ? 'block' : 'none'}`}} className="kurumsal-address">
                                            <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                            <InputMask pattern="[0-9]*" style={{padding:'10px'}} name="VergiNo" placeholder="Vergi No" mask="9999999999"
                                                isValid={touched.VergiNo && !errors.VergiNo} 
                                                onChange={handleChange}
                                                />
                                                {touched.VergiNo && errors.VergiNo ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.VergiNo}
                                                </div> : ''}
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                            <Form.Control
                                                    type="text" placeholder="Vergi Dairesi"
                                                    name="VergiDairesi"
                                                    value={values.VergiDairesi}
                                                    onChange={handleChange}
                                                    isValid={touched.VergiDairesi && !errors.VergiDairesi}
                                                /> 
                                                    {touched.VergiDairesi && errors.VergiDairesi ?
                                                    <div type="invalid" className="validation-error">
                                                        {errors.VergiDairesi}
                                                    </div> : ''}
                                            </Form.Group>
                                        </div> 
                                
                                
                                </Row> 
                            
                                <Button variant="primary" type="submit" style={{float:'right'}}>
                                    Kaydet
                                </Button>
                            </Form>
                        )}
                        </Formik>
               
            
                    
                
               
        </>
    );
}

export default NewAddress;
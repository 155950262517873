import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import loadingGif from '../../images/cart-loader.gif';
import ReactGA from 'react-ga4';


const CreateUserSuccess = () => {

    const history = useHistory();

    useEffect(()=> {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title: "Hesap Oluştur" });
        setTimeout(() => {
            history.push('/account/login')
        }, 2000);
    })

    return(
        <>
            <img src={loadingGif} height="120" width="120" style={{textAlign: 'center'}} alt="yönlendiriliyor" />
        </>
    )
}
export default CreateUserSuccess;
import _ from 'lodash';

const config = require('./config.json');

const defaultConfig = config.development;
let environment = process.env.REACT_APP_ENVIRONMENT;
let environmentConfig = {};
// eslint-disable-next-line import/no-mutable-exports
let finalConfig;
if (environment === 'production') {
  environment = process.env.REACT_APP_ENVIRONMENT;
  environmentConfig = config.production;
  finalConfig = _.merge(defaultConfig, environmentConfig);
} else {
  environment = 'development';
  environmentConfig = config[environment];
  finalConfig = _.merge(defaultConfig, environmentConfig);
}

export default finalConfig;

import React,{useState,useEffect} from 'react';
import { Container, Row,Tabs,Tab} from 'react-bootstrap';
import ForgotMyPassord from './ForgotMyPassword';
import Login from './login';
import SginUp from './sginup';
import { ReactComponent as ReactLogo } from '../../images/right.svg';
import ReactGA from 'react-ga4';
import WithoutAccount from '../Cart/WithoutAccount';


function LoginOrSginUp(props) {
    const [key, setKey] = useState('login');

    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title: "Giriş, Kayıt" });
        return () => {
        document.body.classList.remove('login-or-sginup');
        };
    }, []);

    const handleAccountClick = (k) => {
        document.body.classList.add(k);
        const bodyClassList = document.body.classList.value.split(' ');
        if(bodyClassList !== "") {
            bodyClassList.forEach((e) => {
                if(e !== k && e !== "checkout") {
                    document.body.classList.remove(e);
                }
            });
        }
        if(props.handleAccountClick !== undefined && k === "uyeliksiz")  {
            props.handleAccountClick(k);
        }
        else {
            setKey(k);
        }
    }

    const handleBackClik = (e) =>{
        document.body.classList.remove('forgot-password');
        document.body.classList.add('uyelik');
        setKey(e);
    }


    
    return(
        <Container fluid className="login-or-sginup-full-part">
            <Row>
                <Container className="login-or-sginup-normal-part">
                    <div className="tab-container-part">
                            <Tabs  activeKey={key} onSelect={(k) => handleAccountClick(k)} defaultActiveKey="login" id="login-or-sginup" className="mb-3 login-tab-container">
                                <Tab eventKey="uyeliksiz" className="uyeliksiz" title={<span> <span>Üye olmadan devam et</span> <ReactLogo /></span>} >
                                       <WithoutAccount />
                                    </Tab>
                                <Tab eventKey="login" title="Giriş Yap">
                                    <Login handleAccountClick={props.handleAccountClick}/>
                                </Tab>
                                <Tab eventKey="sginup" title="Üye Ol">
                                    <SginUp />
                                </Tab>
                             
                                <Tab eventKey="forgot-password" title="Şifremi Unuttum">
                                    <ForgotMyPassord handleBackClik={handleBackClik}/>
                                </Tab>
                            </Tabs>
                    </div>
                 {window.screen.width > 500 && window.location.pathname !== "/order/checkout" ?
                    <div className="login-promotion-area">
                        <h1>
                            Efsane Lezzetleri Keşfedin
                        </h1>
                        <p> Sahin724.com 'a üye olarak vereceğiniz her siparişte süprizlerle karşılaşır eşsiz geleneksel lezzelerimizi bir tıkla size ulaştıralım.  </p>
                        <iframe width="100%"  height="200" src="https://www.youtube.com/embed/5UyqPz19VBE?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0" title="Kayseri'nin dillere destan pastırması" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div> : ''}
                </Container>
            </Row>
        </Container>
    );
 
}

export default LoginOrSginUp;
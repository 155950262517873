import { getRequest,postRequest } from '../client/client';
import finalConfig from '../../constans/apiConstants';
import headers from '../../constans/header.json';
import { parseResult } from './dataParser';
import {servicesBaseUrl} from '../../constans'


export const searchUserLogin= async () => {
    const url = servicesBaseUrl+finalConfig.SLIDER;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const searchCityList = async () => {
    const url = servicesBaseUrl+finalConfig.CITY;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchByDistrict = async (cityId) => {
    const url = `${servicesBaseUrl+finalConfig.DISTIRICT}/${cityId}`;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const getToken = async data => {
    const url = `${"https://api.sahin724.com"+finalConfig.TOKEN}`;//http://localhost:3215
    const config = headers.content_type.form_data;
    const result = await postRequest(url,data, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const updateUserInfo = async (data) => {
    const url = `${servicesBaseUrl+finalConfig.UPDATEACCOUNT}`;
    const result = await postRequest(url,data);
    const parsedResult = parseResult(result);
    return parsedResult;
  };
  
export const createUser = async data => {
    const url = servicesBaseUrl+finalConfig.SGINUP;
    const config = headers.content_type.form_data;
    const result = await postRequest(url, data, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const getUserInfo = async token => {
    const url = servicesBaseUrl+finalConfig.PERSONALINFORMATION;
    const result = await getRequest(url); 
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const getFavoriteList = async () => {
    const url = servicesBaseUrl+finalConfig.FOVORITELIST;
    const result = await getRequest(url);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const DeleteFavorite = async (data) => {
    const url = servicesBaseUrl+finalConfig.DELETEFAVORITE;
    const result = await postRequest(url,data);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const AddFavorite = async (data) => {
    const url = servicesBaseUrl+finalConfig.ADDFAVORITE;
    const result = await getRequest(url,data);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const getOrderList = async () => {
    const url = servicesBaseUrl+finalConfig.ORDERLIST;
    const result = await getRequest(url);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const getHashPassword = async (password) => {
    const url = servicesBaseUrl+finalConfig.HASHPASSWOARD+'?';
    const result = await postRequest(url,password);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const getAddressList = async () => {
    const url = servicesBaseUrl+finalConfig.ADDRESSLIST;
    const result = await getRequest(url);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const updateByAddress = async (data) => {
    const url = servicesBaseUrl+finalConfig.UPDATEADDRESS;
    const result = await postRequest(url,data);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const createAddress = async (data) => {
    const url = servicesBaseUrl+finalConfig.CREATEADDRESS;
    const result = await postRequest(url,data);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const deleteByAddress = async (id) => {
    const url = `${servicesBaseUrl+finalConfig.DELETEADDRESS}/${id}`;
    const result = await getRequest(url);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const updateByUserPassword = async (data) => {
    const url = `${servicesBaseUrl+finalConfig.CHANGEPASSWORD}`;
    const result = await postRequest(url,data);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const sendPasswordEmail = async (email) => {
    const url = servicesBaseUrl+finalConfig.FORGOTPASSWORDEMAIL+email;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchPasswordLink = async (link) => {
    const url = servicesBaseUrl +finalConfig.FORGOTPASSWORDLINK+link;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};
export const updatePassword = async (link) => {
    const url = servicesBaseUrl +finalConfig.RESETPASSWORD+link;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const getPoints = async () => {
    const url = servicesBaseUrl+finalConfig.POINTS;
    const result = await getRequest(url);
    const parsedResult = parseResult(result);
    return parsedResult;
};
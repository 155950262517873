import React from 'react';
import notfoundImage from '../../images/404.png';
import { Container } from 'react-bootstrap';

const NotFound = () => {
    return (
        <> 
            <Container fluid className="not-found-page-full-part">
                <Container className="not-found-page-normal-part">
                    <img src={notfoundImage} alt="Sayfa bulunamadı." />
                    <p>Aradığınız sayfa bulunamadı. </p>
                </Container>
            </Container>

        </>
    );
}

export default NotFound;
import React from 'react';
import { Col, Container,Accordion, Row } from 'react-bootstrap';
import {Link , withRouter} from 'react-router-dom';
import facebook from '../../images/SocialMedya/facebook.svg';
import instagram from '../../images/SocialMedya/instagram.svg';
import youtube from '../../images/SocialMedya/youtube.svg';
import twitter from '../../images/SocialMedya/twitter.svg';
import { CategoryList } from "../../constans";


function MobileFooter() {
    const loggedin = localStorage.getItem('access_token');

    return(
        <>
            <Container fluid className="footer-full-part">
                <Row>
                    <Container className="footer-normal-part">
                        <Row>
                            <Col md={12} className="mobile-footer-grid-box" >
                                {/* <img src={Logo} alt="Logo" /> */}
                                <Accordion >
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Kategoriler</Accordion.Header>
                                    <Accordion.Body>
                                        <ul >
                                            {CategoryList.map((category) => {
                                                    return (
                                                    <li key={category.ID} onClick={() => window.scrollTo(0, 0)}>
                                                        <Link
                                                        to={category.KategoriUrl}
                                                        key={category.ID}
                                                        >
                                                        {category.KategoriAdi}
                                                        </Link>
                                                    </li>
                                                    );
                                                })
                                                }
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Kurumsal</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><a rel="noopener noreferrer" href="https://www.sahinsucuklari.com.tr/Kurumsal" target="_blank"> Hakkımızda  </a></li>
                                            <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/gizlilik-ve-kosullar"> Gizlilik ve Koşullar </Link></li>
                                            {/* <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/iletisim"> İletişim</Link></li> */}
                                            <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/helal-belge-ve-sertifikalari"> Helal Sertifikası ve Belgesi</Link></li>
                                            <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/kisisel-verilerin-korunmasi"> Kişisel Verilerin Korunması</Link></li>
                                    </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Bilgilerim</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/siparis-sorgula"> Sipariş Sorgula </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim" : "/account/login"}>Üyelik Bilgilerim </Link> </li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim" : "/account/login"}> Siparişlerim </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/account/login"> Üyelik Tamamlama/Aktifleştir </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim" : "/account/login"}> Ürün Listem </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim" : "/account/login"}>Adres Bilgilerim </Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Yardım</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                        <li> <Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/nedenuye-olmaliyim"> Neden Üye Olmalıyım? </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/teslimat-kosullari"> Teslimat Koşulları </Link> </li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/iade-ve-iptal"> İptal ve İade Şartları </Link> </li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/kolay-alisveris"> Kolay Alışveriş </Link> </li>
                                        <li> <Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/sss">Sıkça Sorulan Sorular </Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                         
                                <ul className="footer-sociial-box">
                                    <h5><Link to="/sayfalar/musteri-hatti" onClick={() => window.scrollTo(0, 0)}> Bize Ulaşın </Link></h5>
                                    <span>724 Şahin Dağıtım Pazarlama Gıda San. Tic. LTD. ŞTİ.</span>
                                    <span>Oruç Reis Mah. 3416 Sok. No:9 Kocasinan- Kayseri</span>
                                    <div className="social-icon">
                                    <li>
                                            <a rel="noopener noreferrer" href="https://www.instagram.com/sahinsucuklari/" target="_blank" > 
                                                    <img src={instagram} alt="instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a  rel="noopener noreferrer" href="https://www.youtube.com/user/sahinsucuklari" target="_blank" >    
                                            <img src={youtube} alt="facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a  rel="noopener noreferrer" href="https://twitter.com/sahinsucuklari" target="_blank" >
                                            <img src={twitter} alt="facebook" /></a>
                                        </li>
                                        <li>
                                        <a rel="noopener noreferrer" href="https://www.facebook.com/sahinsucuklarigida" target="_blank" > 
                                            <img src={facebook} alt="facebook" />
                                            </a>
                                        </li>
                                    </div>
                             
                                </ul>
                           
                            </Col>
                            <div>
                                    <p>
                                        Bu sitede sadece ticari satış işlemleri yapılmaktadır.
                                    </p>
                                    <p>Sitede yer alan tüm görsel ve metinler izinsiz kullanılamaz. Tüm Hakları Saklıdır.</p>
                                </div>
                            <Col md={12} className="footer-mazaka">
                                <a rel="noopener noreferrer" href="https://mazakayazilim.com" target="_blank"> MAZAKA E-ticaret</a> paketleriyle hazırlanmıştır.
                            </Col>
                        </Row>
                    </Container>

                </Row>
            </Container>
        </>
    );
}

export default withRouter(MobileFooter);
import React, { useEffect, useState } from 'react';
import { Tabs,Tab, Container, Row, Col} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { searchAllPageList } from '../../api/service/generalService';
import { slugify } from '../../constans';
import loadingGif from '../../images/cart-loader.gif';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

var HtmlToReactParser = require('html-to-react').Parser;

function StaticPages(props) {
    const [pageList, setPageList] = useState([]);
    const [key, setKey] = useState('home');
    var htmlToReactParser = new HtmlToReactParser();
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState({});
    const history = useHistory();
    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title:"Müşteri Hizmetleri" });
        document.body.classList.add('static-pages')
    });

    useEffect(() => {
        const getPageList = async () => {
            const res = await searchAllPageList();
            if(!res.errStatus) {
                let activePage = [];
                if(res.data.length > 0) {
                    activePage = res.data.filter((e) =>  slugify(e.sayfaBasligi) === props.location.pathname.split('/')[2])
                }
                setActivePage(activePage[0]);
                setPageList(res.data);
                setKey(props.location.pathname.split('/')[2]);
            }
      }
        getPageList();
        return () => {
          document.body.classList.remove('static-pages');
        };
      }, [props.location.pathname]);

   
      const handleTabsChange = (k) => {
        setLoading(true);
        setTimeout(function(){ 
           setLoading(false);
           setKey(k);
           history.push('/sayfalar/'+k); 
        }, 1000);
    }
if(!loading) {
     return(
        <>
            <Helmet>
                <meta name="keywords" content={activePage.slogan}/>
                <meta name="description" content={activePage.slogan} />
                <meta name="og:title" property="og:title" content="Şahin Sucukları Fabrikadan 7/24 Online Satış" />
                <meta property="og:type" content={activePage.sayfaBasligi} />
                <meta property="og:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta property="og:url" content={(window.location.pathname + window.location.search)} />
                <meta property="og:image" content="/Images/sahinLogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:creator" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:title" content="Şahin sucukları kullanıcı girişi ve kayıt " />
                <meta name="twitter:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış." />
                <title> { `${activePage.sayfaBasligi} - Şahin Sucukları Şahin 724 Sipariş`}</title>
            </Helmet>
                
            <Container fluid className="static-page-full-part">
                <Row>
                    <Container className="static-page-normal-part">
                        <Row>
                            <Col md={12} className="static-page-left-box">
                                     <h1>ŞAHİN 724</h1>
                                <Tabs defaultActiveKey="home" activeKey={key}   onSelect={(k) => handleTabsChange(k)} id="uncontrolled-tab-example" className="mb-3 contact-tabs-box">
                                    {/* <Tab eventKey="home" title="Neden Şahin724?"  >
                                            <h3>Müsteri Hep Hakli Hep Mutlu</h3>
                                            <p>Web sitemizden yapacaginiz tüm alisverislerde sebep ne olursa olsun, süre kosulu olmadan, sebep belirtmeden dilediginiz zaman ürünlerimizi iade ederek ürün degisimi veya ücret iadesi gerçeklestirebilirsiniz.
                                            Kosul Yok, Süre Yok! Müsteri Hep Hakli, Hep Mutlu.</p>
                                            <iframe width="80%" height="500" src="https://www.youtube-nocookie.com/embed/8CK_0UqXS-E?controls=0&amp;loop=1&amp;rel=0&amp;showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </Tab> */}
                                    {
                                        pageList.length > 0 ?
                                            pageList.map((page) =>{
                                                return(
                                                    <Tab key={page.ID} eventKey={slugify(page.sayfaBasligi)} title={<span>{page.sayfaBasligi}</span>}>
                                                         {htmlToReactParser.parse(page.Aciklama)}
                                                    </Tab>
                                                );
                                            })
                                        
                                        
                                         :''
                                    }

                                    {/* <Tab eventKey="home" title="Neden Şahin724?"  >
                                        <h3>Müsteri Hep Hakli Hep Mutlu</h3>
                                        <p>Web sitemizden yapacaginiz tüm alisverislerde sebep ne olursa olsun, süre kosulu olmadan, sebep belirtmeden dilediginiz zaman ürünlerimizi iade ederek ürün degisimi veya ücret iadesi gerçeklestirebilirsiniz.
                                        Kosul Yok, Süre Yok! Müsteri Hep Hakli, Hep Mutlu.</p>
                                        <iframe width="80%" height="500" src="https://www.youtube-nocookie.com/embed/8CK_0UqXS-E?controls=0&amp;loop=1&amp;rel=0&amp;showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </Tab>
                                    <Tab eventKey="sertifika" title="Helal Belge ve Sertifikaları">
                                        Alışverişinizde 40 TL Hediye
                                    </Tab>
                                    <Tab eventKey="guvenli" title="Güvenli Alışveriş">
                                        Güvenli Alışveriş
                                    </Tab>
                                    <Tab eventKey="musteri" title="Müşteri Hattı">
                                        Müşteri Hattı
                                    </Tab>
                                    <Tab eventKey="gizlilik" title="Gizlilik ve koşullar">
                                        Gizlilik ve koşullar
                                    </Tab>
                                    <Tab eventKey="kolay" title="Kolay Alışveriş">
                                        Kolay Alışveriş
                                    </Tab>
                                    <Tab eventKey="teslimat" title="Teslimat Koşulları">
                                        Kolay Alışveriş
                                    </Tab>
                                    <Tab eventKey="sss" title="SSS">
                                        SSS
                                    </Tab>
                                    <Tab eventKey="iade" title="İade ve İptal">
                                        İade ve İptal
                                    </Tab>
                                    <Tab eventKey="kvkk" title="Kişisel Verilerin Korunması">
                                       Kişisel Verilerin Korunması
                                    </Tab>
                                    <Tab eventKey="gizlilik-politikasi" title="Gizlilik Politikası">
                                        İade ve İptal
                                    </Tab>
                                    <Tab eventKey="cerez-politikasi" title="Çerez Politikası">
                                        İade ve İptal
                                    </Tab>
                                    <Tab eventKey="veri" title="Veri Öznesi">
                                        Veri Öznesi Başvuru Formunu Doldurarak info@sahin724.com adresine iletebilirsiniz.
                                    </Tab> */}
                                </Tabs>
                            </Col>
                    
                        </Row>
                      
                    </Container>
                </Row>
            </Container>
        </>
    );
}
else {
    return(
        <>
            <Helmet>
                <meta name="keywords" content="üye,giriş,üye ol,giriş yap,şahin,sucuk,pastırma,mantı,sosis,kavurma,salam,özel pastırma,kayseri mantısı,kapıda ödeme"/>
                <meta name="description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta name="og:title" property="og:title" content="Şahin Sucukları Fabrikadan 7/24 Online Satış" />
                <meta property="og:type" content="" />
                <meta property="og:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta property="og:url" content={(window.location.pathname + window.location.search)} />
                <meta property="og:image" content="/Images/sahinLogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:creator" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:title" content="Şahin sucukları kullanıcı girişi ve kayıt " />
                <meta name="twitter:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış." />
                <title>Şahin Sucukları Şahin 724 Sipariş</title>
            </Helmet>
            
             <Container fluid className="static-page-full-part">
                    <Container className="static-page-normal-part">
                        <Row>
                            <Col md={12} className="static-page-left-box">
                                <img alt="loading" src={loadingGif} />

                            </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}
   
}

export default StaticPages;
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { searchByProductDetail } from '../../api/service/generalService';
import ProductSlider from '../ProductSlider';
import campaignSvg from '../../images/campaign.svg';
import vakumluSvg from '../../images/vakumlu.svg'
import customCargo from '../../images/sogukKargo.svg';
import agirlikSvg from '../../images/agirlikSvg.svg';
import { useHistory, Link } from 'react-router-dom';
import hizliKargo from '../../images/hizliKargo.svg';
import 'react-rater/lib/react-rater.css'
import TariflerCart from '../Layout/Recipes/tariflerCart';
import { Dec, Inc } from '../../constans/svgExport';
import loading from '../../images/cart-loader.gif'
import { PropTypes } from 'prop-types';
import { Swiper, SwiperSlide } from "swiper/react";
import {
    addToCart, adjustItemQty, removeToCart
} from '../../action/productAction'
import { connect } from "react-redux";
import DetailMobileAcordion from './detailMobileAcordion';
import AddToCartModal from '../ProductCart/addToCartModal';
import { ldJson } from '../../constans';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
var HtmlToReactParser = require('html-to-react').Parser;

function Detail(props) {
    const [products, setProducts] = useState(null);
    var htmlToReactParser = new HtmlToReactParser();
    const history = useHistory();
    const [addtoCartModal, setAddtoCartModal] = useState(false);
    const handleClose = () => setAddtoCartModal(false);
    const [increment, setIncrement] = useState(0);

    const handleCloseAddtoCart = (unit, id, increment) => {
        id = parseInt(id);
        if (increment !== 0) {
            if (unit === 1) {
                props.adjustItemQty(id, increment)
            }
            else { props.adjustItemQty(id, (increment / 0.25)) }
        }
        else { props.addToCart(id); }
        setAddtoCartModal(true);
    }


    const handleIncrement = (unit) => {
        let unitInc = 0;
        if (increment === 0) {
            if (unit === 1) unitInc = unit + 1;
            else unitInc = unit + 0.25;
        }
        else {
            if (unit === 1) unitInc = increment + 1;
            else unitInc = increment + 0.25;
        }
        setIncrement(unitInc)
    }

    const handleDecrement = (piece, unit) => {
        let unitInc = 0;
        if ((piece === 1 || piece === 0.25) && increment === 0) {
            if (piece === 1) unitInc = piece;
            else unitInc = piece;
        }
        else if (unit === "Adet" && (increment === 0 || increment === 1)) {
            if (piece === 1) unitInc = piece;
            else unitInc = piece;
        }
        else if (unit === "Kg" && (increment === 0.25 || increment === 0)) {
            if (piece === 1) unitInc = piece;
            else unitInc = piece;
        }
        else {
            if (piece === 1) unitInc = increment - 1;
            else unitInc = increment - 0.25;
        }
        setIncrement(unitInc)
    }

    useEffect(() => {
        const getCategoryProducts = async () => {
            const id = props.match.params.id;
            if (id && products === null) {
                const res = await searchByProductDetail(parseInt(id));
                if (!res.errStatus) {
                    const newProduct = res.data[0];
                    newProduct.KategoriLink=props.KategoriLink;
                    newProduct.productUrl = props.location.pathname;
                    /* Facebook Pixel */
                    ReactPixel.fbq('track', 'ViewContent', {
                        'content_ids': '[' + newProduct.ID + ']',
                        'content_type': 'product',
                        'content_name': '[' + newProduct.UrunAdi + ']',
                        'value': newProduct.Fiyat.toString().replaceAll(",", "."),
                        'content_category': newProduct.kategoriUrl.replaceAll("-", " "),
                        'currency': 'TRY'
                    })
                    /* Facebook Pixel End*/
                    /* ReactGA.ga('send', 'event', {
                        'eventCategory': newProduct.KategoriAdi,
                        'eventAction': 'ProductView',
                        'title': newProduct.UrunAdi
                    })
                    ReactGA.pageview(window.location.pathname + window.location.search);*/
                    ReactGA.send({ hitType: "pageview", page:window.location.href, title: newProduct.UrunAdi });
                    ReactGA.event("view_item",{ 
                        items: [
                            {
                              item_id: newProduct.ID,
                              item_name: newProduct.UrunAdi,
                              currency: 'TRY',
                              price: newProduct.Fiyat.toString().replaceAll(",", "."),
                              quantity: 1
                            }
                          ] 
                    })
                    
                    ReactGA.event({
                        category: props.match.params.categoryName,
                        action: "ProductView",
                        label: window.location.href, // optional
                        value: newProduct.UrunAdi, // optional, must be a number
                        nonInteraction: true, // optional, true/false
                        transport: "xhr", // optional, beacon/xhr/image
                      });


                    setProducts(newProduct);
                }
                else {
                    history.push('/sayfalar/sayfa-bulunamadi');
                }
            }
        }
        getCategoryProducts();

    }, [props, products, history]);


    useEffect(() => {
        document.body.classList.add('product-detail');


    });


    useEffect(() => {
        return () => {
            document.body.classList.remove('product-detail');
        };
    }, []);



    if (products !== null) {
        return (
            <>
                <Helmet >
                    <meta charSet="utf-8" />

                    <title>{products.UrunAdi}</title>
                    <meta name="description" content={products.seoDescription} />
                    <meta name="keywords" content={products.seoKeyword} />

                    <meta property="og:title" content={products.seoTitle} />
                    <meta property="og:type" content={products.seoTitle} />
                    <meta property="og:description" content={products.seoDescription} />
                    <meta property="og:url" content={(window.location.href)} />
                    <meta property="og:image" content={"https://www.sahin724.com/products/" + products.Url} />
                    <meta property="og:price:amount" content={products.Fiyat} />
                    <meta property="og:price:currency" content='TRY' />

                    <meta property="twitter:image" content={"https://www.sahin724.com/products/" + products.Url} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:data1" content="price" />
                    <meta name="twitter:label1" content={products.Fiyat} />
                    <meta name="twitter:site" content={(window.location.href)} />
                    <meta name="twitter:creator" content={(window.location.href)} />
                    <meta name="twitter:title" content={products.seoTitle} />
                    <meta name="twitter:description" content={products.seoDescription} />

                    <meta property="product:brand" content="Şahin" />
                    <meta property="product:availability" content="in stock" />
                    <meta property="product:condition" content="new" />
                    <meta property="product:price:amount" content={products.Fiyat} />
                    <meta property="product:price:currency" content="TRY" />
                    <meta property="product:retailer_item_id" content={products.ID} />

                    <div itemscope itemtype="http://schema.org/Product">
                        <meta itemprop="brand" content="Şahin" />
                        <meta itemprop="name" content={products.UrunAdi} />
                        <meta itemprop="description" content={products.seoDescription} />
                        <meta itemprop="productID" content={products.ID} />
                        <meta itemprop="url" content={(window.location.href)} />
                        <meta itemprop="image" content={"https://www.sahin724.com/products/" + products.Url} />
                        <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
                            <link itemprop="availability" href="In stock" />
                            <link itemprop="itemCondition" href="new" />
                            <meta itemprop="price" content={products.Fiyat} />
                            <meta itemprop="priceCurrency" content="TRY" />
                        </div>
                    </div>


                    <script type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson(products)) }}
                    />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson(products))}
                    </script>
                </Helmet>
                <Container fluid className="product-detail-full-part">
                    <Row>
                        <Container className="product-slider-normal-part">
                            <Row>
                                <div className="breadcrumb">
                                    <ol>
                                        <li>
                                            <Link to="/">
                                                Anasayfa
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/' + products.kategoriUrl}>  
                                                 {
                                                String.prototype.turkishToLower = function(){
                                                    var string = this;
                                                    var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
                                                    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function(letter){ return letters[letter]; })
                                                    return string.toLowerCase();
                                                }
                                            }
                                                {products.KategoriAdi.turkishToLower()}
                                                
                                                
                                            </Link>
                                        </li>
                                        <li>
                                            <span>
                                                {products.UrunAdi}
                                            </span>
                                        </li>
                                    </ol>

                                </div>
                                <Col md={6} className="product-detail-left-box">
                                    <ProductSlider imageUrl={"https://www.sahin724.com/products/" + (products !== null ? products.Url : '')} isim={products.UrunAdi} fotolar={products.resimler}/>
                                </Col>
                                <Col md={6} className="product-detail-right-box">
                                    <div className="product-detail-desc-box">
                                        <h1>{products.UrunAdi}</h1>
                                        {/* <div className="product-detail-rating">
                                            <p>Ürün Kodu: {products.Barkod}</p>
                                            {products.Yorum.length > 0 ? (
                                                <div>
                                                    <Rater rating={(products.urunPuan)/products.Yorum.length} total={5} interactive={false} /> 
                                                    <span>5,0</span>
                                                    <span className="reviews">{products.Yorum.length} Değerlendirme</span>
                                                </div>
                                            ): ''}
                                        </div> */}
                                    </div>
                                    {products.Aciklama !== null ? <p className="product-detail-sort-desc-box">{htmlToReactParser.parse(products.Aciklama)}</p> : ''}
                                    {/*
                                    <p className="product-detail-sort-desc-box">İster mangalda ister tavada bu lezzet bambaşka. Şahin Sucukları’nın klasik
                                        vazgeçilmez ürünü Parmak Sucuğu’nu hala denemediyseniz, bi’ tıkla,
                                        fabrikadan kapına.</p>
                                    */}
                                    <div className="product-detail-price-and-save">
                                        {products.Durumu ?
                                            <>
                                                <div className="numbers-row">
                                                    <Button className="dec" onClick={() => handleDecrement(products.Adet, products.Birim)}>
                                                        {Dec()}
                                                    </Button>
                                                    <div> <span>{increment === 0 ? products.Adet : increment} <span>{products.Birim}</span></span></div>
                                                    <Button className="inc" onClick={() => handleIncrement(products.Adet)}>
                                                        {Inc()}
                                                    </Button>
                                                </div>

                                                <div className="product-price">
                                                    {products.Fiyat2 !== null ? <p className="eski-fiyat">{products.Fiyat2} TL</p> : ''}
                                                    {products.Fiyat !== null ? <p className="fiyat">{products.Fiyat} TL</p> : ''}
                                                </div> </> : ''

                                        }
                                    </div>

                                    <div className="add-to-cart">
                                        {
                                            products.Durumu ? <Button type="submit" onClick={() => handleCloseAddtoCart(products.Adet, props.match.params.id, increment)}>
                                                Sepete Ekle
                                            </Button> : <Button type="submit" disabled>
                                                Satışa Kapalı
                                            </Button>
                                        }

                                    </div>

                                    {products.urunKampanyaMetni.length > 0 ? <div className="campaign-box">
                                        <img src={campaignSvg} alt="kampanya ikonu" />
                                        <p> {htmlToReactParser.parse(products.urunKampanyaMetni[0])}</p>
                                    </div> : ''}
                                    <div className="product-detail-cargo-area">
                                        <div className="custom-cargo">
                                            <img src={customCargo} alt="96 saat dayanabilen kargo" />
                                            <span>96 Saat Dayanabilen Özel Kargo Paketi</span>

                                        </div>
                                        <div className="custom-cargo">
                                            <img src={vakumluSvg} alt="Türkiye’de tek özel gıda Vakumlu paket!" />
                                            <span>Türkiye’de tek özel gıda Vakumlu paket!</span>

                                        </div>
                                        <div className="custom-cargo">
                                            <img src={hizliKargo} alt="Aynı gün kargoya teslim!" />
                                            <span>Aynı gün kargoya teslim!</span>
                                        </div>
                                        <div className="custom-cargo">
                                            <img src={agirlikSvg} alt="Aynı gün kargoya teslim!" />
                                            <span>İstediğiniz ağırlıklarda paketlenir!</span>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={12} className="product-detail-tabs">
                                    <DetailMobileAcordion id={props.match.params.id} Aciklama={products.Aciklama} Video={products.Video} Yorum={products.Yorum} Aciklama2={products.Aciklama2} />
                                </Col>
                                {products.tarifler.length > 0 ?
                                    <Col className="lezzetler-card-box">
                                        <h2><span> {products.UrunAdi}</span> ile Lezzet Tarifleri</h2>

                                        <Swiper slidesPerView={window.screen.width < 600 ? 1.2 : 3.8} spaceBetween={20} navigation={true} freeMode={true} pagination={{
                                            "clickable": true
                                        }} className="mySwiper">
                                            {
                                                products.tarifler.map(lezzet =>
                                                    <SwiperSlide key={lezzet.ID}>
                                                        <TariflerCart
                                                            id={lezzet.ID}
                                                            key={lezzet.ID}
                                                            imageUrl={lezzet.ImageUrl}
                                                            kategoriAdi={lezzet.KategoriAdi}
                                                            KategoriLink={lezzet.KategoriLink}
                                                            kisaAciklama={lezzet.KisaAciklama}
                                                            sira={lezzet.Sira}
                                                            tarifAdi={lezzet.TarifAdi}
                                                            tarifKisaAciklama={lezzet.TarifKisaAciklama}
                                                            video={lezzet.Video}

                                                        />
                                                    </SwiperSlide>

                                                )
                                            }
                                        </Swiper>
                                    </Col> : ''}
                            </Row>
                        </Container>
                    </Row>
                </Container>
                <AddToCartModal
                    addtoCartModal={addtoCartModal}
                    handleClose={handleClose}
                    productName={products.UrunAdi}
                    imageUrl={"https://www.sahin724.com/products/" + products.Url}
                    piece={products.Adet}
                    unit={products.Birim}
                    priceDiscount={products.Fiyat2}
                    price={products.Fiyat}
                    increment={increment === 0 ? products.Adet : increment}
                />
            </>
        );
    }
    else {
        return (
            <div className="site-loading">
                <img src={loading} alt="loading" />
            </div>
        );

    }

}


const mapDispatchToProps = dispatch => {
    return {
        addToCart: id => dispatch(addToCart(id)),
        removeToCart: id => dispatch(removeToCart(id)),
        adjustItemQty: (id, qty) => dispatch(adjustItemQty(id, qty))
    }
}
const mapStateToProps = (state) => ({
    cart: state.productList.cart,
});

Detail.propTypes = {
    addToCart: PropTypes.func.isRequired,
    removeToCart: PropTypes.func.isRequired,
    cart: PropTypes.array.isRequired,
    adjustItemQty: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);

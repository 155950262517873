import React from 'react';
import { Container } from 'react-bootstrap';

const PastirmaStaticPage = () => {
    return(
        <>
            <Container fluid className="pastirma-tanitim-full-part">
                <Container className="pastirna-tanitim-normal-part">
                    <h1> Pastırma Nasıl Hazırlanır ve Çeşitleri</h1>
                    <p>
                        Nasıl pastırma seçmelisiniz? En iyi pastırma nedir?  </p>
                      <p>  Pastırmanın yağlı olanı mı tercih edilmeli, yağsız olanı mı? </p>
                      <p> Özel evlik pastırma nedir, Pastırma'yı Kayseri'den nereden alabilirsiniz?  </p>
                      <p> Şahin Sucukları olarak 1923 'ten bu yana pastırma 'nın doğduğu topraklarda geleneksel lezzetlerimizi sizlere sunmaktayız. </p>
                      <p> Lezzet deneyiminize özel olarak pastırmalar hakkında hazırladığımız tanıtım videosunu inceleyerek tercihinize kolaylıkla karar verebilirisiniz. </p>
                   
                    <iframe width="100%" height="720" src="https://www.youtube.com/embed/videoseries?list=PLnxCvas0Tcy5LI1fyT1zag834N7gxloh2&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0" title="Pastırma Nasıl Hazırlanır ve Çeşitleri" 
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Container>
            </Container>

        </>
    )
}
export default PastirmaStaticPage;
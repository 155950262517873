import React, { useEffect, useState } from 'react';
import { getPoints } from '../../../api/service/loginServices';
import PointEmptyMessage from './PointEmptyMessage';
import PointList from './PointList';
import { Col, Row } from 'react-bootstrap';

const Points = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchPointMessage = async () => {

            await getPoints()
                .then((e) => {
                    if (!!e.data) {
                        setDataList(e.data);
                    }
                })
                .catch((e) => {
                    console.error("getPoints - ERROR ", e)
                })
                .finally(() => {
                    setLoading(false);
                })
        };

        fetchPointMessage();
    }, []);

    return (
        <>
            <div>
                <h4>
                    Puanlarım
                </h4>
                {
                    dataList?.length !== 0 && (
                        <p className="text-secondary" style={{fontSize:"small"}}>
                            {dataList.length} Sonuç Listelendi
                        </p>
                    )
                }
            </div>
            {
                !loading ? dataList.length !== 0 ? (
                    <PointList dataList={dataList} />
                ) : (
                    <PointEmptyMessage />
                ) :
                    (
                        <Row className="text-center">
                            <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                <div style={{ minHeight: 300, background: "lightgray" }}></div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                <div style={{ minHeight: 300, background: "lightgray" }}></div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                <div style={{ minHeight: 300, background: "lightgray" }}></div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                <div style={{ minHeight: 300, background: "lightgray" }}></div>
                            </Col>
                        </Row>
                    )
            }
        </>
    );
}


export default Points;
import React,{ useRef, useState} from 'react';
import { Form,Button,Col,Row,Alert } from 'react-bootstrap';
import {withRouter, useHistory} from 'react-router-dom';
import { Formik } from 'formik';
import {getToken,getHashPassword} from '../../api/service/loginServices'
import Cookies from 'universal-cookie';
import { eMailValidation } from '../../constans';
import Helment from 'react-helmet';

function Login(props) {
    const [show, setShow] = useState(false);
    const form = useRef(null);
    const cookies = new Cookies();
    const history = useHistory();

    const handleSubmit = async (event) => {
        const hashPassword = await getHashPassword( {Password: event.password.toString()});
        if(!hashPassword.errStatus) {
            var tokendata = "grant_type=password&username=" + event.username + "&password=" + hashPassword.data;
            try{
                const res = await getToken(tokendata);
                if(!res.errStatus) {
                    localStorage.setItem('access_token',res.data.access_token);
                    localStorage.setItem('loggedIn', JSON.stringify(true))
                    handleSetCookie(res.data.access_token, res.data.expires);  
                    if(props.handleAccountClick !== undefined)  {
                        document.body.classList.remove('login-failed');
                        document.body.classList.remove('uyelik');
                        props.handleAccountClick();
                       
                    }
                    else {
                        history.push("/");
                        document.body.classList.remove('login-failed')
    
                    }
                }
                else {
                    document.body.classList.add('login-failed')
                    if(show){
                        setShow(false)
                        setTimeout(() => {
                            setShow(true)
                        }, 120);
    
                    }
                    else {
                        setShow(true)
                    }
                }
            }
            catch {
                document.body.classList.add('login-failed');
            }

           
        }
        
    };

    const handleSetCookie = (token,expiresDate) => {
        cookies.set('access_token', token, { path: '/', expires: expiresDate });
    }

    const handleClose = () => {
        document.body.classList.remove('login-failed')
        setShow(false)
    }

    return(
        <>
            <Helment>
                <meta name="keywords" content="üye,giriş,üye ol,giriş yap,şahin,sucuk,pastırma,mantı,sosis,kavurma,salam,özel pastırma,kayseri mantısı,kapıda ödeme"/>
                <meta name="description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta name="og:title" property="og:title" content="Şahin Sucukları Fabrikadan 7/24 Online Satış" />
                <meta property="og:type" content="Sucuk" />
                <meta property="og:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta property="og:url" content={(window.location.pathname + window.location.search)} />
                <meta property="og:image" content="/Images/sahinLogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:creator" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:title" content="Şahin sucukları kullanıcı girişi ve kayıt " />
                <meta name="twitter:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış." />
                <title>Giriş Yap - Kayıt Ol - Şahin Sucukları Şahin 724 Sipariş</title>
            </Helment>
           <Alert  variant={"danger"}  onClose={() => handleClose()} dismissible show={show}>
                <p>Hatalı kullanıcı adı şifre</p>

            </Alert>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    username: '',
                    password: '',
                }}
                validationSchema={eMailValidation}
                >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                }) => (
                <Form ref={form} noValidate onSubmit={handleSubmit}>
                    <Row className="mb-12 login-input-box">
                        <Form.Group as={Col} md="12" controlId="username" className="email-input">
                            <Form.Control
                                type="email" placeholder="Email adresinizi giriniz"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                isValid={touched.firstName && !errors.firstName}
                            />
                             {touched.username && errors.username ?
                                    <div type="invalid" className="validation-error">
                                        {errors.username}
                                    </div> : ''}
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="password" className="password-input">
                                <Form.Control
                                    type="password" placeholder="Şifrenizi giriniz"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                  
                        <Button variant="primary" type="submit">
                            Giriş Yap
                        </Button>
                    </Form>
                )}
                </Formik>
                
        </>
    );
}



export default withRouter(Login);
import React from 'react';
import {Modal,Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { addtoCart } from '../../constans/svgExport';

const AddToCartModal = (props) => {
    return(
            <Modal show={props.addtoCartModal} centered={window.screen.width < 478} onHide={props.handleClose} className="product-add-to-cart-modal">
                <Modal.Header closeButton>
                <Modal.Title>Ürün Sepete Eklendi</Modal.Title>
                    {addtoCart()}
                </Modal.Header>
                <Modal.Body>
                    {
                        props.siparisKalemleri !== undefined ? 
                        <div className="siparis-tekrarla">
                            {props.siparisKalemleri.map((e) => {
                                return(
                                    <div className='order-list-modal' key={e.kalemId}>
                                        <div className="product-cart-image-box" >
                                            {e.urunBilgileri !== undefined ?
                                                <img className="cart-image" src={"https://www.sahin724.com/products/"+e.urunBilgileri.image} alt={e.urunAdi} />
                                                : e.urunFotograflari !== null ? 
                                                <img className="cart-image" src={"https://www.sahin724.com/products/"+e.urunFotograflari?.image} alt={e.urunAdi} />
                                            : ''}
                                            <div className="product-name-unit">
                                                <h3>
                                                    {e.urunAdi}    
                                                </h3>
                                            
                                            </div>
                                        
                                        </div>
                                        <div className="product-price">
                                        <span className="product-count">
                                                        <span>({e.increment}  </span>
                                                        <span>{e.birim})</span>
                                                </span>
                                            {e.indirimYuzdesi !== 0 && e.indirimYuzdesi !== null ? <p className="eski-fiyat">{e.indirimYuzdesi} TL</p> : ''}
                                            {e.birimFiyat !== null ? <p className="fiyat">{e.adet === 1 ? e.birimFiyat * e.adet : e.adet * e.birimFiyat} TL</p> : ''}
                                        </div>
                                    </div>
                                )
                            })}
                         </div>
                        :
                        <>
                               <div className="product-cart-image-box" >
                                    <img className="cart-image" src={props.imageUrl} alt={props.ProductName} />
                                    <div className="product-name-unit">
                                        <h3>
                                            {props.productName}    
                                        </h3>
                                    
                                    </div>
                                
                                </div>
                                <div className="product-price">
                                <span className="product-count">
                                                <span>({props.increment}  </span>
                                                <span>{props.unit})</span>
                                        </span>
                                    {props.priceDiscount !== 0 && props.priceDiscount !== null ? <p className="eski-fiyat">{props.priceDiscount} TL</p> : ''}
                                    {props.price !== null ? <p className="fiyat">{props.piece === 1 ? props.increment * props.price : props.increment * props.price * 4} TL</p> : ''}
                                </div>
                        </>
                    }
                
                </Modal.Body>
                <Modal.Footer>
                <div className="link-box">
                        <Button variant="secondary" onClick={props.handleClose} className="continue-shop">Alışverişe Devam Et</Button>

                        <Link to="/cart/index" onClick={() => window.scrollTo(0, 0)} >
                            Sepete Git
                        </Link>
                    </div>
                </Modal.Footer>
            </Modal>   
    );
}

export default AddToCartModal;
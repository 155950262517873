import React, { useEffect, useState, useRef } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import removeIcon from '../../../images/remove.svg';
import CartStaticArea from '../CartStatictArea';
import Campaign from '../Campaign';//aktif 17.09.23
import { connect } from "react-redux";
import { searchCartCampaignCheck } from '../../../api/service/cartServices';
import { categoryBaseUrl } from '../../../constans'
import { Dec, Inc } from '../../../constans/svgExport';
import { PropTypes } from 'prop-types';
import Logo from "../../../images/sahinlogo.svg";
import { fetchProductList, addToCart, removeToCart, campainCheckState, removeItemToCart } from '../../../action/productAction';
import * as ProductListAction from '../../../types/productType';
 

import Loader from '../../../images/cart-loader.gif'
import { Formik } from 'formik';
import ReactGA from 'react-ga4';
import TimerWarning from '../../TimerWarning';
/*import CargoCampaign from '../Campaign/cargoCampaign';*///pasif 17.09.23


function CartIndex(props) {
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [cartCapaignProduct, setCartCapaignProduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const form = useRef();

    useEffect(() => {
        document.body.classList.add('cart')
    });

    useEffect(() => {
        if (
            props.productListFetchCount === 0 &&
            props.productListFetchState !==
            ProductListAction.ProductListFetchStates.FETCHING
        ) {
            props.dispatchFetchProductList();
        } else if (
            props.productListFetchState ===
            ProductListAction.ProductListFetchStates.FETCHED
        ) {
            let items = 0;
            let price = 0;
            const urunInfo = [];
            const cartData = {};
            const cart = JSON.parse(localStorage.getItem('state'));
            if (cart.length !== 0) {
                const filterCart = cart.filter((e) => e.qty > 0);
                if (cart.length !== filterCart.length) {
                    localStorage.setItem('state', JSON.stringify(filterCart));
                    filterCart.forEach((item) => {
                        items += item.qty;
                        if (item.Unit === "Kg") {
                            price += item.qty * item.Price;
                        }
                        else {
                            price += item.qty * item.Price;
                        }
                        urunInfo.push({ UrunID: item.ProductId, adet: item.Unit === "Kg" ? item.qty / 4 : item.qty });

                    });
                }
                else {
                    cart.forEach((item) => {
                        items += item.qty;
                        if (item.Unit === "Kg") {
                            price += item.qty * item.Price;
                        }
                        else {
                            price += item.qty * item.Price;
                        }
                        urunInfo.push({ UrunID: item.ProductId, adet: item.Unit === "Kg" ? item.qty / 4 : item.qty });

                    });
                }

            }
            else {
                setCartCapaignProduct([]);
            }

            if (urunInfo.length > 0) {
                cartData.urunInfo = urunInfo;
                cartData.sepetToplam = price;
                cartData.kampanyaTuru = 'products';
                localStorage.setItem('sepetKontrol', JSON.stringify(cartData));
                const getCampaignCheck = async (data) => {

                    await searchCartCampaignCheck(data).then((res) => {
                        const cart = JSON.parse(localStorage.getItem('state'));
                        const cartList = [];
                        setLoading(false);
                        if (!res.errStatus) {
                            cartList.push(cart);
                            cartList.push(res.data);
                            const sepetOzet = cartList[1];
                            sepetOzet.totalProductPrice = price;
                            localStorage.setItem('sepetOzet', JSON.stringify(sepetOzet))
                            setCartCapaignProduct(cartList);
                            props.campainCheckState(cartList[1]);

                        }
                    }).catch(() => {
                        //window.location.reload();
                    })

                }
                getCampaignCheck(cartData);
            }
            else {
                setLoading(false);
            }
            setTotalItems(items);
            setTotalPrice(price);
        }

    }, [props, totalPrice, totalItems, setTotalPrice, setTotalItems]);


    const faceBookPixcelEvent = () => {
        const cart = JSON.parse(localStorage.getItem('state'));
        const reducer = (previousValue, currentValue) => previousValue + currentValue;
        import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init('184192118716429') // facebookPixelId
          ReactPixel.pageView()
          ReactPixel.fbq('track', 'InitiateCheckout', {
            content_ids: '[' + cart.map((e) => e.ProductId).toString()  + ']',
            content_type: 'product',
            value: (cart.map((e) => {
                let price = 0;
                if (e.Unit === "Kg") price += e.qty * e.Price;
                else price += e.qty * e.Price;
                return price
            })).reduce(reducer),
            currency: 'TRY'
          })
        })
    }
    

    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Sepet" });
        return () => {
            
            document.body.classList.remove('cart');
        };
    }, [props]);

    const handleSubmit = async (e) => {
        if (e.cuponCode !== "") {
            const cart = JSON.parse(localStorage.getItem('sepetKontrol'));
            if (cart !== null) {
                cart.cuponCode = e.cuponCode;
                localStorage.setItem('kuponCode', e.cuponCode);
                await getCampaignCheck(cart);
            }
        }
    }
    const getCampaignCheck = async (data) => {
        const res = await searchCartCampaignCheck(data);
        const cart = JSON.parse(localStorage.getItem('state'));
        const cartList = [];
        if (!res.errStatus) {
            cartList.push(cart);
            cartList.push(res.data);
            if (res.data.HediyeUrun === null) {
                const cartFreeProduct = JSON.parse(localStorage.getItem('cartFreeProduct'));
                if (cartFreeProduct !== null) localStorage.removeItem('cartFreeProduct');
            }
            setCartCapaignProduct(cartList);
            localStorage.setItem('sepetOzet', JSON.stringify(cartList[1]));
            props.campainCheckState(cartList[1]);

        }
    }
    if (JSON.stringify(localStorage.getItem('state') !== null)) {
        if (cartCapaignProduct.length > 0) {
            return (
                <>
                    <Container fluid className="cart-index-header-full-part">
                        <Container className="cart-index-header-normal-part">
                            <Link to="/">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Container>
                    </Container>
                 
                   
                    {
  (
    (new Date() > new Date('2024-06-09') && new Date() < new Date('2024-06-20')) ?
    <Container fluid className="cart-index-full-part">
      <Container className="cart-index-normal-part">
        <div style={{ color: 'red', margin: '15px 0px' }}>
        Kurban bayramı tatili nedeni ile 12.06.2024 15:00'den sonra verilen siparişler 20.06.2024'te kargoya verilecektir.
        </div>
      </Container>
    </Container>
    : ''
  )
}
                                  
                    {/*Aşağı pasif edildi 17-09-23
                     <CargoCampaign getCampaignCheck={getCampaignCheck} hediyeSepetToplam={cartCapaignProduct[1].hediyeSepetToplam.toFixed(2)}
                        hediyeUrun={cartCapaignProduct[1].HediyeUrun} isMessageOrProduct={cartCapaignProduct[1].HediyeUrun !== null}
                        campaignMesaj={cartCapaignProduct[1].kampanyaMesaj} /> */}
                   
                    {/*Aşağı aktif edildi 17-09-23*/}

                 

                    <Campaign getCampaignCheck={getCampaignCheck} hediyeSepetToplam={cartCapaignProduct[1].hediyeSepetToplam.toFixed(2)}
                        hediyeUrun={cartCapaignProduct[1].HediyeUrun} isMessageOrProduct={cartCapaignProduct[1].HediyeUrun !== null}
                        campaignMesaj={cartCapaignProduct[1].kampanyaMesaj} />
                        
                    <Container fluid className="cart-index-full-part">
                        <Container className="cart-index-normal-part">
                            <Row>
                                <TimerWarning />
                                <h1>Sepetim</h1>
                                <Col md={8} className="cart-index-left-part">
                                    {cartCapaignProduct[0].length > 0 ?
                                        cartCapaignProduct[0].map(product => {
                                            return (

                                                <div className="cart-product-list" key={product.ProductId}>
                                                    <div className="product-image-box">
                                                        <img src={categoryBaseUrl + product.ImageUrl} alt={product.ProductName} />
                                                    </div>
                                                    <div className="product-desc-box">
                                                        {/* <span>Ürün Kodu {product.Barcode}</span> */}
                                                        <h5>{product.ProductName}</h5>
                                                    </div>
                                                    <div className="number-row-and-unit">
                                                        {/* <span>{product.Price} x ({product.Piece} {product.Unit} x {product.qty})</span> */}
                                                        <div className="numbers-row">
                                                            <Button className="dec" disabled={product.Unit === "Kg" ? product.qty === 0.25 : product.qty === 1} onClick={() => props.removeItemToCart(product.ProductId)}>
                                                                {Dec()}
                                                            </Button>
                                                            <span>( {product.Unit === "Kg" ? (product.qty / 4) : product.qty} {product.Unit})</span>
                                                            <Button className="inc" onClick={() => props.addToCart(product.ProductId)}>
                                                                {Inc()}
                                                            </Button>
                                                        </div>

                                                    </div>
                                                    <div className="product-price-box">
                                                        {product.PriceDiscount !== 0 ? <span className="product-discount">
                                                            {product.Unit === "Kg" ? product.PriceDiscount * ((product.qty / 4)) : product.PriceDiscount * product.qty} TL</span> : ''}
                                                        <span>{product.Unit === "Kg" ? product.Price * ((product.qty / 4) / 0.25) : product.qty * product.Price} TL</span>
                                                        {/* <span>( {product.qty*0.25} {product.Unit})</span> */}
                                                    </div>
                                                    <Button className="remove-button" onClick={() => props.removeToCart(product.ProductId)} >
                                                        <img src={removeIcon} alt="remove-button" />
                                                    </Button>
                                                </div>

                                            );
                                        })
                                        : <div className="site-loading">
                                            <img src={Loader} alt="loader" />

                                        </div>}

                                </Col>
                                <Col md={4} className="cart-index-right-part">
                                    <h2>Sipariş Özeti</h2>
                                    <div className="order-product-price">
                                        <span>Ürünler</span>
                                        <span>{totalPrice} TL</span>
                                    </div>
                                    <div className="order-shipping-price">

                                        <span>
                                            <span>Kargo</span>
                                            {cartCapaignProduct[1].kargoTutari === 0 ? <span className="cargo-bedava">(Bedava)</span> : ''}
                                            <span className="cargo-note">Yurtiçi Kargo Özel Soğuk Kargo Paketi</span>
                                            <span className="cargo-mesaj">{cartCapaignProduct[1].kargoMesaj}</span>
                                        </span>
                                        <span className="cargo-price">{cartCapaignProduct[1].kargoTutari} TL</span>
                                    </div>
                                    {
                                        cartCapaignProduct[1].indirimMiktari !== 0 ?
                                            <div className="order-product-price indirimler">
                                                <span>İndirimler</span>
                                                <span>{ cartCapaignProduct[1].indirimMiktari.toFixed(2) } TL</span>
                                            </div>
                                            : ''
                                    }

                                    <div className="kupon-uygulama">
                                        <Formik
                                            onSubmit={handleSubmit}
                                            initialValues={{
                                                cuponCode: '',
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                values,
                                                errors,
                                            }) => (
                                                <Form ref={form} noValidate onSubmit={handleSubmit}>
                                                    <Row className="mb-12 cupon-form">
                                                        <Form.Group as={Col} md="12" controlId="name" className="cupon-input">
                                                            <Form.Control
                                                                type="text" placeholder="Kupon kod"
                                                                name="cuponCode"
                                                                value={values.cuponCode}
                                                                onChange={handleChange}
                                                                isValid={cartCapaignProduct[1].indirimMiktari !== 0}

                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.username}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Button variant="primary" type="submit" style={{ float: 'right' }}>
                                                            Kodu Uygula
                                                        </Button>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                        {/* {cartCapaignProduct[1].kuponMesaj ? <p>{htmlToReactParser.parse(cartCapaignProduct[1].kuponMesaj)}</p> : ''} */}
                                    </div>
                                    <div className="kdv-total-price">
                                        <span>Toplam (KDV dahil)</span>
                                        <span>{cartCapaignProduct[1].sepetToplam.toFixed(2)} TL</span>
                                    </div>
                                    <Link to="/order/checkout" onClick={faceBookPixcelEvent} className="order-chcekout-btn">
                                        Şimdi Satın Al
                                    </Link>
                                    <Link to="/">
                                        Alışverişe Geri Dön
                                    </Link>
                                    {window.screen.width < 500 ?
                                        <div className="fixed-area">
                                            <div className="kdv-total-price">
                                                <span> Sepet toplam </span>
                                                <span>{cartCapaignProduct[1].sepetToplam.toFixed(2)} TL</span>
                                            </div>
                                            <Link to="/order/checkout" className="order-chcekout-btn">
                                                Şimdi Satın Al
                                            </Link>
                                        </div> : ''}
                                </Col>
                                <CartStaticArea />
                            </Row>
                        </Container>
                    </Container>
                </>
            );
        }
        else if (loading) {
            return (
                <>
                    <Container fluid className="cart-index-header-full-part">
                        <Container className="cart-index-loading-normal-part">
                            <Link to="/">
                                <img src={Loader} alt="logo" />
                            </Link>
                        </Container>
                    </Container>
                </>
            )
        }
        else {
            return (
                <>
                    <Container fluid className="cart-index-header-full-part">
                        <Container className="cart-index-header-normal-part">
                            <Link to="/">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Container>
                    </Container>
                    <Container fluid className="cart-index-full-part">
                        <Container className="no-cart-index-normal-part">
                            <Row>
                                {/* <h1>Sepetim</h1> */}
                                <div className="no-cart-svg">
                                    <svg version="1.1" id="katman_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 100 100" enableBackground="new 0 0 100 100" space="preserve">
                                        <g>
                                            <path fill="#25C45A" d="M36.7,100c-0.6-0.2-1.2-0.3-1.9-0.5c-6.6-2.1-9.8-9.4-6.7-15.7c0.1-0.2,0.2-0.5,0.2-0.5
                                        c-1.6-0.8-3.4-1.3-4.8-2.3c-3-2-4.5-4.9-4.8-8.5c0-0.6,0-1.2,0-1.9c0-19.5,0-38.9,0-58.4c0-0.9-0.2-1.3-1.1-1.6c-4-1.6-8-3.3-12-5
                                        C4.1,5,3.4,3.7,3.7,2.4c0.4-1.8,2.1-2.7,3.9-1.9C11.3,2,15,3.6,18.7,5.1c1.2,0.5,2.5,1,3.7,1.5s1.9,1.5,1.9,2.8c0,3.1,0,6.3,0,9.6
                                        c2,0.3,3.9,0.6,5.8,0.8c8.2,1.1,16.5,2.3,24.7,3.4c7.8,1.1,15.6,2.2,23.3,3.2c5.3,0.7,10.5,1.4,15.8,2.2c1.5,0.2,2.5,1.3,2.5,2.8
                                        c0,7.8,0.1,15.6,0,23.4c-0.1,6.2-5.1,11-11.2,11c-18.3,0-36.7,0-55,0c-1.8,0-3.6-0.4-5.3-1.2c-0.2-0.1-0.3-0.1-0.6-0.2
                                        c0,2.8-0.2,5.5,0,8.1c0.2,2.9,2.6,4.9,5.4,5c0.5,0,0.9,0,1.4,0c16.9,0,33.8,0,50.7,0c5.2,0,9.4,2.8,11.1,7.5
                                        c2.3,6.4-1.9,13.5-8.6,14.7c-0.2,0-0.4,0.1-0.5,0.2c-0.9,0-1.8,0-2.7,0c-0.2-0.1-0.4-0.1-0.5-0.2c-6.8-1.2-10.9-8.1-8.6-14.6
                                        c0.2-0.7,0.5-1.3,0.8-2c-8.2,0-16.4,0-24.7,0c0.1,0.3,0.2,0.4,0.3,0.6c2.8,5.8,0.3,12.6-5.6,15.2c-0.9,0.4-2,0.6-2.9,0.9
                                        C38.7,100,37.7,100,36.7,100z M24.2,24.7c0,0.5,0,0.7,0,1c0,9.5,0,18.9,0,28.4c0,3.9,2.4,6.3,6.3,6.3c18,0,35.9,0,53.9,0
                                        c3.9,0,6.3-2.4,6.3-6.2c0-6.5,0-13,0-19.5c0-0.6-0.2-0.9-0.9-1c-3.5-0.4-7-0.9-10.4-1.4C71.7,31.2,63.9,30.1,56,29
                                        c-7.8-1.1-15.6-2.2-23.4-3.2C29.9,25.4,27.1,25,24.2,24.7z M82.4,94.4c3.1,0,5.6-2.5,5.6-5.6s-2.6-5.6-5.7-5.6s-5.6,2.5-5.6,5.6
                                        C76.7,91.8,79.3,94.4,82.4,94.4z M43.8,88.9c0.1-3.1-2.4-5.7-5.5-5.7c-3.1-0.1-5.7,2.4-5.7,5.5c-0.1,3.1,2.4,5.6,5.5,5.7
                                        C41.1,94.5,43.7,92.1,43.8,88.9z"/>
                                        </g>
                                    </svg>
                                    <h3>Sepetin şu an boş</h3>
                                    <p>
                                        Sepetini Şahin724’ün lezzet dünyasından doldurmak için <Link to="/">ürünleri</Link> incelemeye başlayabilirsin.
                                    </p>
                                </div>

                            </Row>
                        </Container>
                    </Container>
                </>
            )
        }
    }
    else {
        return (
            <>
                Henüz sepete bir ürün eklemediniz.
            </>
        )
    }


}

const mapDispatchToProps = dispatch => {
    return {
        addToCart: id => dispatch(addToCart(id)),
        removeToCart: id => dispatch(removeToCart(id)),
        campainCheckState: item => dispatch(campainCheckState(item)),
        removeItemToCart: item => dispatch(removeItemToCart(item)),
        dispatchFetchProductList: () => dispatch(fetchProductList())
    }
}
const mapStateToProps = (state) => ({
    cart: state.productList.cart,
    productList: state.productList.productList,
    productListFetchCount: state.productList.productListFetchCount,
    productListFetchState: state.productList.productListFetchState,

});

CartIndex.propTypes = {
    addToCart: PropTypes.func.isRequired,
    removeToCart: PropTypes.func.isRequired,
    cart: PropTypes.array.isRequired,
    productListFetchState: PropTypes.string.isRequired,
    productListFetchCount: PropTypes.number.isRequired,
    dispatchFetchProductList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartIndex);  
import React , {useState} from 'react';
import {Card,Button,Modal} from 'react-bootstrap';
import {Link,useHistory} from 'react-router-dom';
import { slugify } from '../../../constans';

var HtmlToReactParser = require('html-to-react').Parser;

const TariflerCart = (props) => {
    const [show, setShow] = useState(false);
    var htmlToReactParser = new HtmlToReactParser();
    const history = useHistory()
    return(
        <>  
            <Card >
                <Button variant="primary" onClick={() => props.video !== null ? setShow(true) :history.push("/tarif/yeni-lezzetler/"+slugify(props.tarifAdi)+'/'+props.id)}>
                    <Card.Img variant="top" loading="lazy" src={'https://www.sahin724.com'+props.imageUrl} />
                </Button>
                    <Card.Body>
                    <Link to={"/tarif/yeni-lezzetler/"+slugify(props.tarifAdi)+'/'+props.id}>
                        <Card.Title>{props.tarifAdi}</Card.Title>
                        <Card.Text>
                            {props.tarifKisaAciklama}
                        </Card.Text>
                        </Link>

                    </Card.Body>
                </Card>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="custom-modal-styling-title"
                    className="tarifler-modal"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="custom-modal-styling-title">
                        {props.tarifAdi}
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       {htmlToReactParser.parse(props.video)}
                    </Modal.Body>
                </Modal>
        </>
    );
}

export default TariflerCart;
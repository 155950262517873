import React, { useEffect, useState } from 'react';
import {Container, Col,Row} from 'react-bootstrap';
import { searchAllLezzetler } from '../../../api/service/generalService';
import TariflerCart from './tariflerCart';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Recipes(props) {
    const [lezzetler, setLezzetler] = useState([]);
       
    useEffect(() => {
        getLezzetler()
    },[])
    useEffect(() => {
        document.body.classList.add('lezzetler-category')

    });


    useEffect(() => {
        return () => {
            document.body.classList.remove('lezzetler-category');
        };
    }, []);

    async function getLezzetler() {
        const res = await searchAllLezzetler();
        if(!res.errStatus) {
            setLezzetler(res.data);
        }
        
    }

    return(
        <>
            <Helmet>
                <meta name="keywords" content="tarif,kagıtta pastırma,sucuklu yemekler,şahin sucuk tarifleri,mantı çorbası,kayseri mantısı,sucuklu milföy,pastırmalı açma"/>
                <meta name="description" content="Şahin sucukları tarifler. Kayserinin yöresel ve eşsiz lezzetleriyle yapılan yemek tarifleri." />
                <meta name="og:title" property="og:title" content="Şahin Sucukları Tarifler" />
                <meta property="og:type" content="Tarifler" />
                <meta property="og:description" content="Şahin sucukları tarifler. Kayserinin yöresel ve eşsiz lezzetleriyle yapılan yemek tarifleri."/>
                <meta property="og:url" content={(window.location.pathname + window.location.search)} />
                <meta property="og:image" content="/Images/sahinLogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:creator" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:title" content="Şahin sucukları tarifler. Kayserinin yöresel ve eşsiz lezzetleriyle yapılan yemek tarifleri." />
                <meta name="twitter:description" content="Şahin sucukları tarifler. Kayserinin yöresel ve eşsiz lezzetleriyle yapılan yemek tarifleri." />
               
            </Helmet>
             <Container fluid className="recipes-full-part">
                <Row>
                    <Container className="recipes-normal-part">
                        <h1>Lezzetler</h1>
                    <div className="breadcrumb">
                        <ol>
                            <li>
                                <Link to="/">
                                    Anasayfa
                                </Link>
                            </li>
                            <li>
                                <span>
                                    Lezzetler 
                                </span>
                            </li>
                        </ol>                                  
                    </div>
                        <Col className="lezzetler-card-box">
                       
                            {
                                lezzetler.map(lezzet => 
                                        <TariflerCart 
                                            id={lezzet.ID}
                                            key={lezzet.ID}
                                            imageUrl={lezzet.ImageUrl}
                                            kategoriAdi={lezzet.KategoriAdi}
                                            KategoriLink={lezzet.KategoriLink}
                                            kisaAciklama={lezzet.KisaAciklama}
                                            sira={lezzet.Sira}
                                            tarifAdi={lezzet.TarifAdi}
                                            tarifKisaAciklama={lezzet.TarifKisaAciklama}
                                            video={lezzet.Video}

                                        />

                                )
                            }
                        </Col>
                    </Container>
                </Row>
              
            </Container>
        </>
    );
}

export default Recipes;
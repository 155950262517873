import React, { useEffect } from 'react';
import { Tabs,Tab, Container, Row, Col} from 'react-bootstrap';
import NedenSahin from './nedenSahin';
import phoneSvg from '../../images/phone.svg'
import ReactGA from 'react-ga4';
import Helment from 'react-helmet';

function Contact() {

    useEffect(() => {
        document.body.classList.add('contact')
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title: "İletişim" });
    });

    return(
        <>
               <Helment>
               <meta name="keywords" content="üye,giriş,üye ol,giriş yap,şahin,sucuk,pastırma,mantı,sosis,kavurma,salam,özel pastırma,kayseri mantısı,kapıda ödeme"/>
                <meta name="description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta name="og:title" property="og:title" content="Şahin Sucukları Fabrikadan 7/24 Online Satış" />
                <meta property="og:type" content="Sucuk" />
                <meta property="og:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış."/>
                <meta property="og:url" content={(window.location.pathname + window.location.search)} />
                <meta property="og:image" content="/Images/sahinLogo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:creator" content={(window.location.pathname + window.location.search)} />
                <meta name="twitter:title" content="Şahin sucukları kullanıcı girişi ve kayıt " />
                <meta name="twitter:description" content="Şahin Sucukları online fabrika satış mağazası. Sucuk, pastırma, kavurma ve diğerleri bir tıkla kapında. Giriş yap ve süprizlerle tanış." />
                <title>Giriş Yap - Kayıt Ol - Şahin Sucukları Şahin 724 Sipariş</title>
            </Helment>
            <Container fluid className="contact-full-part">
                <Row>
                    <Container className="contact-normal-part">
                        <Row>
                            <Col md={10} className="contact-left-box">
                                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3 contact-tabs-box">
                                    <Tab eventKey="home" title="Neden Şahin724?"  >
                                        <NedenSahin />
                                    </Tab>
                                    <Tab eventKey="profile" title="İptal & İade">
                                    İptal İade
                                    </Tab>
                                    <Tab eventKey="contact" title="Soğuk gönderim">
                                        Soğuk gönderim
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col md={2} className="contact-right-box">
                           
                                <img src={phoneSvg} alt="telefon" />
                                <h1>İletişim Bilgileri</h1>
                                <a href="tel:4441371" >444 13 71</a>
                                <span>Email: <a href="mailto:info@sahin724.com">info@sahin724.com</a></span>
                            </Col>
                        </Row>
                      
                    </Container>
                </Row>
            </Container>
        </>
    );
}

export default Contact;
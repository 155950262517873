import React , {useEffect,useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../../images/sahinlogo.svg';
import {Link , withRouter} from 'react-router-dom';
import facebook from '../../images/SocialMedya/facebook.svg';
import instagram from '../../images/SocialMedya/instagram.svg';
import youtube from '../../images/SocialMedya/youtube.svg';
import twitter from '../../images/SocialMedya/twitter.svg';
import { CategoryList } from "../../constans";
import MobileFooter from './mobileFooter';


function Footer() {

    const [resolution , setResolution] =useState(0);
    const loggedin = localStorage.getItem('access_token');

    useEffect(() => {
        setResolution(window.screen.width);
    },[])
    
    if(resolution > 768) {
        return(
            <>
                <Container fluid className="footer-full-part">
                    <Row>
                        <Container className="footer-normal-part">
                            <Row>
                                <Col md={12} className="footer-category-box" >
                                    <img src={Logo} alt="Logo" />
                                    <ul >
                                        {CategoryList.map((category) => {
                                                return (
                                                <li key={category.ID}>
                                                    <Link
                                                    onClick={() => window.scrollTo(0, 0)}
                                                    to={category.KategoriUrl}
                                                    key={category.ID}
                                                    >
                                                    {category.KategoriAdi}
                                                    </Link>
                                                </li>
                                                );
                                            })
                                            }
                                    </ul>
                                    <p>
                                        Bu sitede sadece ticari satış işlemleri yapılmaktadır.
                                    </p>
                                    <p>Sitede yer alan tüm görsel ve metinler izinsiz kullanılamaz. Tüm Hakları Saklıdır.</p>
                                </Col>
                                <Col md={12} className="footer-link-box">
                                    <ul>
                                        <h5>Kurumsal</h5>
                                        <li><a rel="noopener noreferrer" href="https://www.sahinsucuklari.com.tr/Kurumsal" target="_blank"> Hakkımızda  </a></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/gizlilik-ve-kosullar"> Gizlilik ve Koşullar </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/musteri-hatti"> İletişim</Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/helal-belge-ve-sertifikalari"> Helal Sertifikası ve Belgesi</Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/kisisel-verilerin-korunmasi"> Kişisel Verilerin Korunması</Link></li>
                                    </ul>
                                    <ul>
                                        <h5> Bilgilerim </h5>
                                        <li><Link  onClick={() => window.scrollTo(0, 0)}  to="/sayfalar/siparis-sorgula"> Sipariş Sorgula </Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)}  to={loggedin ? "/account/hesabim?kisisel-bilgilerim" : "/account/login"}>Üyelik Bilgilerim </Link> </li>
                                        <li><Link  onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim?siparislerim" : "/account/login"}> Siparişlerim </Link></li>
                                        <li><Link  onClick={() => window.scrollTo(0, 0)} to="/account/login"> Üyelik Tamamlama/Aktifleştir </Link></li>
                                        {/* <li><Link  onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim" : "account/login"}> Ürün Listem </Link></li> */}
                                        <li><Link  onClick={() => window.scrollTo(0, 0)} to={loggedin ? "/account/hesabim?adreslist" : "/account/login"}>Adres Bilgilerim </Link></li>
                                    </ul>
                                    <ul>
                                        <h5>Yardım</h5>
                                        <li> <Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/nedenuye-olmaliyim"> Neden Üye Olmalıyım?</Link></li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/teslimat-kosullari"> Teslimat Koşulları </Link> </li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/iade-ve-iptal"> İptal ve İade Şartları </Link> </li>
                                        <li><Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/kolay-alisveris"> Kolay Alışveriş </Link> </li>
                                        <li> <Link onClick={() => window.scrollTo(0, 0)} to="/sayfalar/sss">Sıkça Sorulan Sorular </Link></li>
                                    </ul>
                                    <ul className="footer-sociial-box">
                                        <h5>Bize Ulaşın</h5>
                                        <span>724 ŞAHİN DAĞITIM PAZARLAMA SAN. ve TİC. A.Ş.</span>
                                        <span>Oruç Reis Mah. 3416 Sok. No:9 Kocasinan- Kayseri</span>
                                        <li>
                                            <a rel="noopener noreferrer" href="https://www.instagram.com/sahinsucuklari/" target="_blank" > 
                                                    <img src={instagram} alt="instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a rel="noopener noreferrer" href="https://www.youtube.com/user/sahinsucuklari" target="_blank" >    
                                            <img src={youtube} alt="facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a rel="noopener noreferrer" href="https://twitter.com/sahinsucuklari" target="_blank" >
                                            <img src={twitter} alt="facebook" /></a>
                                        </li>
                                        <li>
                                        <a  rel="noopener noreferrer" href="https://www.facebook.com/sahinsucuklarigida" target="_blank" > 
                                            <img src={facebook} alt="facebook" />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                                <div className="footer-mazaka">
                                   <a rel="noopener noreferrer" href="https://mazakayazilim.com" target="_blank"> 
                                   MAZAKA E-ticaret </a> paketleriyle hazırlanmıştır.
                                </div>
                            </Row>
                        </Container>
    
                    </Row>
                </Container>
               
            </>
        );
    }
    else{
        return(
            <>
                 <MobileFooter />
            </>
        );
    }
  
}

export default withRouter(Footer);
import React from 'react';

function kampanyaMetni() {
    return(
        <>
            <p><u>Gizlilik Politikası</u> 724 Şahin Dağıtım Pazarlama Gıda Sanayi ve Ticaret Limited Şirketi (“<strong>Şirket</strong>”) tarafından kişisel verilerimin aşağıdaki amaçlar dahilinde işlenmesine açık rıza verir ve siparişime ilişkin vermiş olduğum&nbsp; iletişim bilgileri ve adreslerime bu amaçlar doğrultusunda Şirket’in hizmet aldığı araştırma şirketleri ve/veya aracı hizmet sağlayıcıları tarafından reklam, promosyon, kampanya ve benzeri ticari elektronik ileti gönderilmesine onay veririm.</p>
            <p>İşbu Gizlilik Politikasında (“<strong>Gizlilik Politikası</strong>”), 6698 sayılı Kişisel Verilerin Korunması Kanunu (“<strong>Kanun</strong>”) kapsamındaki aydınlatma yükümlülüğümüz çerçevesinde 724 Şahin Dağıtım Pazarlama Gıda Sanayi ve Ticaret Limited Şirketi (“<strong>Şirket</strong>”) olarak, <a href="https://www.sahin724.com/Index.html">https://www.sahin724.com/Index.html</a> adresli internet sitesinin ("<strong>Site</strong>") işletilmesi sırasında siz Site ziyaretçisi/kullanıcılarının Site aracılığıyla toplanan verilerini ve bu verileri nasıl kullandığımızı açıklamayı amaçlamaktadır.</p>
            <p>&nbsp;</p>
            <p>Kişisel Verilerinizin işlenmesine ilişkin daha detaylı bilgiye <a href="https://www.sahin724.com/sayfalar/kvkk">https://www.sahin724.com/sayfalar/kvkk</a> &nbsp;linkinde yer alan 724 Şahin Dağıtım Pazarlama Gıda Sanayi ve Ticaret Limited Şirketi Kişisel Verilerin Korunması Politikası’ndan ulaşabilirsiniz.</p>
            <h2><strong>&nbsp;</strong></h2>
            <h2  ><strong>Veri Sorumlusunun Kimliği</strong></h2>
            <p><strong>&nbsp;</strong></p>
            <p>Kişisel verileriniz bakımından Kanun uyarınca Veri Sorumlusu; Kayseri Ticaret Sicili Müdürlüğü nezdinde 44651 sicil numarası ile kayıtlı, 0001096757300019 Mersis numaralı, şirket merkezi Oruçreis Mah. Karpuzatan Cad. Şahin Sucukları Sit. Sevkiyat Girişi No:89 No:/ Kocasinan/Kayseri adresinde bulunan 724 Şahin Dağıtım Pazarlama Gıda Sanayi ve Ticaret Limited Şirketi’dir.</p>
            <h3  ><strong>Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebepler </strong></h3>
            <p>&nbsp;</p>
            <p>Şirketimiz, yürütmekte olduğu tüm kişisel veri işleme faaliyetlerinde Kanun başta olmak üzere ilgili mevzuatta aranan tüm yükümlüklere uygun olarak hareket etmekte ve kişisel verilerinizin güvenli bir şekilde barındırılması ve kişisel verilerinizin hukuka aykırı biçimde kullanılmasının önlenmesi için gerekli güvenlik tedbirlerini almaktadır.</p>
            <p>&nbsp;</p>
            <p>Site’de yürütülen faaliyetler kapsamında kişisel verileriniz, Kanun madde 5/2 çerçevesinde Şirketimiz tarafından aşağıda belirtilen kanallar aracılığıyla otomatik veya otomatik olmayan yollarla elektronik şekilde toplanmakta ve Şirketimiz’in hukuki yükümlülüklerini yerine getirebilmesi, sizinle satış sözleşmelerinin imzalanması ve ifası ile veri öznelerinin temel hak ve özgürlüklerine zarar vermemek kaydıyla Şirketimiz’in meşru menfaatleri kapsamında işlenmektedir;</p>
            <ul>
            <li><a href="https://www.sahin724.com/Index.htm">Site’de</a> yer alan Şirketimiz ile iletişime geçmenize ve görüş ve önerilerinizi iletmenize yarayan “<em>İletişim</em>” sekmesi,</li>
            <li>Site’miz üzerinden üye kaydı oluşturmanız halinde,</li>
            <li>Site’miz üzerinden yapacağınız alışverişleriniz kapsamında alışverişinizi tamamlayabilmeniz ve fatura düzenleyebilmemiz için gerekli alanların doldurulması,</li>
            <li>Sitemiz’de yer alan online destek hattı üzerinden,</li>
            <li><a href="mailto:info@mechsoft.com.tr'ye"><u>info@sahin724.com</u>’ye</a> göndermiş olduğunuz elektronik posta aracılığıyla,</li>
            <li>Site’de yer alan Çerezler ve benzer teknolojiler (Sitede kullanılan çerezler prensip olarak kişiyi belirlenebilir kılmamaktadır, lütfen detaylı bilgi için <a href="https://www.sahin724.com/sayfalar/cerez-politikasi">https://www.sahin724.com/sayfalar/cerez-politikasi</a> linkinde yer alan Çerez Politikası’nı inceleyiniz).</li>
            </ul>
            <h3  ><strong>Kişisel Verilerinizin İşlenme Amaçları </strong></h3>
            <p>&nbsp;</p>
            <p>Kişisel verileriniz, Site’nin ziyareti, Site’de sunulan hizmetlerden faydalanabilmeniz, Site aracılığıyla bize ulaştırdığınız görüş ve önerileriniz ile ürünlerimize / hizmetlerimize yönelik soru ve cevaplarınızın takibi ve gerekli yönlendirmelerin yapılması, Şirketimiz nezdinde oluşturulan kayıtlar vasıtasıyla müşterilerin satışa konu ürün ve hizmetlerden faydalandırılması, &nbsp;satış ve satış sonrası operasyonlar ile satın alma operasyonlarının yürütülmesi, sistem yönetimi, sorunların belirlenmesi ve Site’nin geliştirilmesi, siparişlerinizin alınması ve mesafeli satış sözleşmesinin ifası, Site ve koşullarındaki değişiklikler ile ilgili önemli güncellemelerin tarafınıza gönderilmesi, sizin ya da üçüncü kişilerin haklarının, güvenliklerinin, mülkiyetlerinin ve operasyonlarının korunması ve gerekli olduğu durumda savunma hakkını kullanabilmesi veya resmi makamların taleplerine ya da iletişim konularına cevap verilmesi amacıyla işbu Gizlilik Politikası uyarınca işlenebilecektir.</p>
            <p>&nbsp;</p>
            <p>Toplanan kişisel verileriniz (i) Şirket tarafından sunulan hizmet/ürünlerin pazarlama ve tanıtımı için hizmet alınan araştırma şirketleri ve aracı hizmet sağlayıcıları aracılığıyla pazar araştırması faaliyetlerinin yapılması ve bu ürün ve hizmetlere bağlılık oluşturulması ve arttırılması süreçlerinin planlanması ve icrası, (ii) Şirket tarafından sunulan hizmet/ürünlere yönelik tüketici memnuniyeti aktivitelerinin planlanma ve icrası, memnuniyet anketlerinin iletilmesi ve tüketici deneyiminin iyileştirilmesine yönelik faaliyetlerin yürütülmesi, (iii) Şirketimiz tarafından sunulan hizmet/ürünlere yönelik promosyon, kampanya, tanıtım süreçlerinin planlanması ve icrası, (iv) Şirket tarafından sunulan hizmet/ürünlerin tüketicilerin tercih, alışkanlık ve taleplerine göre özelleştirilerek önerilmesi için uygun olan çalışmaların planlanması ve icrası<strong>, </strong>(v) Şirketimizin ürün ve hizmetlerinden tüketicilerin faydalanma sürekliliği ve devamlılığı da göz önünde bulundurularak müşteriye katalog ve kampanyaların müşteriye önerilmesi ve ulaştırılması için gerekli çalışmaların yapılması ile &nbsp;(vi) Şirket tarafından sunulan hizmet/ürünlerden tüketicileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi amaçlarıyla açık rıza vermeniz halinde Şirket tarafından işlenebilecektir.</p>
            <p>&nbsp;</p>
            <h3  ><strong>Kişisel Verilerinizin Üçüncü Kişilere ve/veya Yurtdışına Aktarımı </strong></h3>
            <p>&nbsp;</p>
            <p>Kişisel verileriniz işbu Gizlilik Politikası’nın “Kişisel Verilerinizin İşlenme Amaçları” başlıklı bölümünde belirtilen amaç ya da amaçlar doğrultusunda Kanun’un 8. ve 9. maddeleri kapsamında belirtilen aktarım şartları çerçevesinde yurt içinde veya yurtdışında bulunan, <strong>(i)</strong></p>
            <h3  ><strong>Haklarınız</strong></h3>
            <p><strong>&nbsp;</strong></p>
            <p>Kanun’un 11. maddesi uyarınca veri özneleri,</p>
            <ul>
            <li>kişisel veri işlenip işlenmediğini öğrenme,</li>
            <li>kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
            <li>yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
            <li>kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
            <li>kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme</li>
            </ul>
            <p>haklarına sahiptir.</p>

        </>
    );

}

export default kampanyaMetni;
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {CampaignSvg } from '../../../constans/svgExport'
import { Swiper ,SwiperSlide} from "swiper/react";
import SwiperCore, {
    Pagination
  } from 'swiper/core';
import FreeProduct from './freeProduct';
  SwiperCore.use([Pagination]);

var HtmlToReactParser = require('html-to-react').Parser;

const Campaign = (props) =>{
    const [freeTotalPrice,setFreeTotalPrice] = useState(0);
    var htmlToReactParser = new HtmlToReactParser();
    
    useEffect(() => {
        const freeCard = JSON.parse(localStorage.getItem('cartFreeProduct'));
        if(freeCard !== null && freeCard.length>0){
            const reducer = (previousValue, currentValue) => previousValue + currentValue;
            const arr = freeCard.map((e) => e.qty * e.Price);
            setFreeTotalPrice(arr.reduce(reducer));
        }
    },[]);
    
    const handleFreeProdutPrice = (totalPrice) => {
        setFreeTotalPrice(totalPrice)
    }
    if(!props.isMessageOrProduct){
        if(props.campaignMesaj !== null){
            return(
                <>
                    <Container fluid className="campaign-full-part">
                        <Container className="campaign-message-normal-part">
                            {CampaignSvg('white')}
                            <div className="campaign-mesaj">
                                <span>{htmlToReactParser.parse(props.campaignMesaj)}</span>  
                            </div>
                        </Container>
                    </Container>
                </>
            );
        }
        else {
            return(
                <>
                
                </>
            )
        }
   
    }
    else {
        if(props.hediyeUrun !== null) {
              return(
            <>
            <Container fluid className="campaign-full-part">
                <Container className="campaign-normal-part">
                <h3>Sepet Kampanyası</h3>
                    <div className="campaing-desc-part">
                        <div className="campaing-message-part">
                        <p>
                        {htmlToReactParser.parse(props.campaignMesaj)}
                         </p>
                         <p style={{color:'black',fontWeight:'bold'}}>Hediye Limitiniz: {htmlToReactParser.parse(props.hediyeSepetToplam)}</p> 
                        </div>
                        {
                        <div className="campaing-total-price-part">
                            <span>Seçilen Hediye Tutarı: {freeTotalPrice} TL</span>
                        </div> }
                    </div>
                    <Swiper slidesPerView={window.screen.width < 400 ? 1.5 :(window.screen.width < 600 ? 2.5 : (window.screen.width < 1200 ? 3 : 6.5))} 
                    spaceBetween={30} navigation={true} freeMode={true} pagination={{
                                            "clickable": true
                                            }} className="mySwiper">
                        {props.hediyeUrun.map(urun => {
                                    return(
                                        <SwiperSlide key={urun.urunID}>
                                            <FreeProduct
                                                    handleFreeProdutPrice= {handleFreeProdutPrice}
                                                    urunID={urun.urunID}
                                                    imageUrl = {urun.imageUrl}
                                                    productName = {urun.productName}
                                                    fiyat={urun.fiyat}
                                                    hediyeSepetToplam={props.hediyeSepetToplam}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                      </Swiper>
                        </Container>
                </Container>
            </>
        )
        }
      
    }
    
}

 export default Campaign;
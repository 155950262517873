import React, { useState, useEffect } from 'react';
import AddressList from './addressList';
import NewAddress from './newAddress';
import { Modal, Button } from 'react-bootstrap';
import { getAddressList } from '../../../api/service/loginServices';

function Address(props) {
    const [show, setShow] = useState(false);
    const [addressList, setAddressList] = useState();
    const handleShow = (data) => {
        setShow(true);
    }

    useEffect(() => {
        const loggedIn = JSON.parse(localStorage.getItem('loggedIn'))
        if (loggedIn && !addressList) {
            getAddressLists();
        }
    });
    const handleClose = () => {
        if (props.handleAddressList !== undefined) {
            props.handleAddressList();
        }
        setShow(false);
    }

    const getAddressLists = async () => {
        const res = await getAddressList();
        if (!res.errStatus) {
            setAddressList(res.data);
        }
    }

    return (
        <>
            <div className="new-address-button">
                <Button onClick={() => handleShow()}>
                    Adres Ekle
                </Button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adres Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewAddress getAddressLists={getAddressLists} handleClose={handleClose} />
                </Modal.Body>

            </Modal>
            <AddressList getAddressLists={getAddressLists} addressList={addressList} />

        </>
    );
}

export default Address;
import React, {useState,useRef } from 'react';
import {  updateByUserPassword } from '../../api/service/loginServices';
import { Form ,Button,Modal,Container, Row,Col,Toast} from 'react-bootstrap';
import { Formik } from 'formik';
import KampanyaMetni from '../Policy/kampanyaMetni';
import loadingGif from '../../images/cart-loader.gif';

function ChangePassword(props) {
    const form = useRef(null);
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleClose = () => setShow(false);
    
    const handleSubmit = async (event) => {    
        if(event.password === event.newPassword)  {
            setErrorMessage("Mevcut şifreniz ile yeni şifreniz aynı olamaz");
            setShowMessage(true);

        }
        else if(event.newPassword.length < 5) {
            setErrorMessage("Yeni şifreniz en az 5 karakter olmalıdır.");
            setShowMessage(true);

        }
        else {
            const res = await updateByUserPassword(event);
            if(res.data){
                setErrorMessage("Girilen mevcut şifreniz hatalıdır.");
                setShowMessage(true);
            }
            else {
                setErrorMessage("Şifreniz başarıyla değiştirildi.");
                setShowMessage(true);
                window.location.reload();
            }
        }
      
    };

    if(props.userInfo) {
        return(
            <>
              <Toast onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="mr-auto">Şahin724</strong>
                        <small>Uyarı Mesajı</small>
                    </Toast.Header>
                    <Toast.Body>{errorMessage}</Toast.Body>
                    </Toast>
                <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        className="cantrat-modal"
                        >
                        <Modal.Header closeButton>
                        <Modal.Title>724 TİCARİ ELEKTRONİK İLETİ ONAY METNİ VE AÇIK RIZA METNİ</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <KampanyaMetni />
                        </Modal.Body>
                    </Modal>
                    <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                       
                        password:'',
                        newPassword: ''
    
                    }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                    }) => (
                    <Form ref={form} noValidate onSubmit={handleSubmit}>
                        <Row className="mb-12 login-input-box">
                             <Form.Group as={Col} md="12" controlId="password" className="password-input">
                              <Form.Control
                                    type="password" placeholder="Mevcut Şifre"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                /> 
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                         
                            <Form.Group as={Col} md="12" controlId="password" className="password-input">
                              <Form.Control
                                    type="password" placeholder="Yeni Şifre"
                                    name="newPassword"
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    isValid={touched.lastName && !errors.lastName}
                                /> 
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            </Row>
                           
                            <Button variant="primary" type="submit">
                                 Güncelle
                            </Button>
                        </Form>
                    )}
                    </Formik>
    
                    
            </>
        );
    }
  
    return(
        <>
            <Container fluid className="static-page-full-part">
                    <Container className="static-page-normal-part">
                        <Row>
                            <Col md={12} className="static-page-left-box">
                                <img alt="loading" src={loadingGif} />

                            </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );

}

export default ChangePassword;
import React,{useRef,useState} from "react";
import { Container,Popover,OverlayTrigger, Row, Col} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import araIcon from "../../images/arahemengelsin.png";
import Logo from "../../images/sahinlogo.svg";
import girisYap from "../../images/girisYap.svg";
import sepet from "../../images/Sepetim.svg";
import { CategoryList } from "../../constans";
import { connect } from "react-redux";
import PopoverCart from "../PopoverCart";
import MobileHeader from "./mobileHeader";
import { cartIcon, loginIcon, orderTracking, phoneIcon } from "../../constans/svgExport";
import {
  addToCart,removeToCart,removeItemToCart,campainCheckState
} from '../../action/productAction';
import { fetchProductList } from '../../action/productAction';
import { PropTypes } from 'prop-types';
import { getSearchKey } from '../../api/service/generalService';
import { slugify } from "../../constans";
import {InputGroup,FormControl} from 'react-bootstrap';

const Header = (props) => {
  const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
  const [popoverShow, setPopoverShow] = useState(false)
  const target = useRef(null);

  const [show, setShow] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [result, setResult] = useState([]);
  const [search, setSearch] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFocus = () => {
    setSearchFocus(true);
  }

  const handleUnFocus = () => {
    setSearchFocus(false);

  }
  const handleChange = async (e) => {
    const key = e.target.value;
     if(e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
       const res = await getSearchKey(key);
       if(!res.errStatus){
         localStorage.setItem('searchResult',JSON.stringify(res.data));
         setResult(res.data);
         setSearch(key);
       }
     }

   }

  const popover = ( 
   <Popover id="popover-basic" className="dropdown-menu"  onMouseLeave={()=> setPopoverShow(false)} >
    <Popover.Body>
      <ul>
          {CategoryList.map((category) => {
              if(category.SubCategory !== undefined) {
                  return (
                    category.SubCategory.map((subCategory) => {
                      return(
                        <li key={subCategory.ID} >
                          <Link
                            to={subCategory.KategoriUrl}
                            key={subCategory.ID}
                          >
                            {/* <img src={subCategory.ImageUrl} alt={subCategory.KategoriAdi} /> */}
                            {subCategory.KategoriAdi}
                          </Link>
                      </li>
                      );
                    })
                  
                  );
                }
                else {
                  return(<div key={category.ID}></div>)
                }
              })
            }
          </ul>
          {/* <Link className="tumunu-incele" to="/sarkuteri"> <p>Tümünü İncele </p> </Link> */}
    </Popover.Body>
  </Popover>  
    /*  <div></div>*/ 
);
 

  if(window.screen.width > 768) {
    return (
      <>
      <Container fluid className="header-top-full-part">
        <Container className="header-top-normal-part">
              <Col className="header-grid-part" md={12}>
              
        <div className="header-grid-part-70"> 
                  <ul>
                    <li>
                      <Link to={"/sayfalar/siparis-sorgula"}>Sipariş Takibi</Link>
                    </li>
                    <li>
                      <Link to={"/sayfalar/neden-sahin724"}>Neden Şahin 7/24</Link>
                    </li>
                    <li>
                      <Link to={"/tarif/yeni-lezzetler"}>Lezzet Tarifleri</Link>
                    </li>
                    <li>
                      <Link to={"/sayfalar/nedenuye-olmaliyim"}>Neden Üye Olmalıyım</Link>
                    </li>
                    <li>
                      <Link className="satist" to={"/sayfalar/kurumsal-satis"}>Şahin Toptan</Link>
                    </li> 
                    <li className="tel-box">
                      <div className="tel-text-box">
                      <a href="tel:4441371">
                        <img src={araIcon} alt="arama iconu" />
                        <span> Ara Hemen Gelsin</span></a>
                      </div>
                      <a href="tel:4441371">444 13 71</a>
                    </li>
                  </ul>
                 
                </div>
                <div className="header-grid-part-30"> 
              <div className="search-menu-part"> 
            <InputGroup>
              <FormControl onMouseOut={handleUnFocus} onChange={(e) => handleChange(e)} onFocus={handleFocus} id="search-input" aria-label="Aramak istediğiniz kelimeyi yazınız." placeholder="Ör: Sucuk"/>
              <InputGroup.Text>
                {search !== "" ?  <Link to={'/search-result?arama='+search}>Ara</Link> : "Ara"}
              </InputGroup.Text>
          </InputGroup>
         
        </div>
        <ul style={{display: searchFocus ? 'block' : 'none'}} className="search-result">
            {result.length > 0 ?
                result.map((product) =>{
                  return(
                      <div key={product.UrunId} className="product-list">
                        <Link to={ (slugify(product.kategoriName.toLowerCase()) +'/' +slugify(product.UrunAdi)+"/"+product.UrunId)}>
                            <img src={"https://www.sahin724.com/products/"+product.UrunImage} alt={product.UrunImage}/>
                              <h6>{product.UrunAdi}</h6>
                              <p>{product.UrunFiyat} TL</p>
                        </Link>
                      </div>
                  );
                })
            :  
            ''}
        </ul> 
        </div>

                </Col>
        </Container>
      </Container>
        <Container fluid className="header-full-part">
          <Row>
            <Container className="header-normal-part">
           
              <Row>
                <Col className="header-category-list">
                  <Row>
                    <Col md={1} className="logo-part">
                      <Link to="/">
                        <img src={Logo} alt="Logo" />
                      </Link>
                    </Col>
                    <Col md={11} className="menu-part">
                      <ul>
                          {CategoryList.map((category) => {
                            if(category.SubCategory === undefined) {
                                return (
                                  <li key={category.ID}>
                                    <Link
                                      to={category.KategoriUrl}
                                      key={category.ID}
                                    >
                                      {category.KategoriAdi}
                                    </Link>
                                  </li>
                                );
                              }
                              else {
                                return (
                                  <li key={category.ID} onMouseLeave={()=> setPopoverShow(false)} >
                                    <OverlayTrigger trigger={["hover","focus"]} onToggle={() => setPopoverShow(true)}  show={popoverShow} target={target.current} placement="bottom-end" overlay={popover}>
                                      <Link
                                        to={category.KategoriUrl}
                                        key={category.ID}
                                        onClick={() => { return true;}}
                                      >
                                      {category.KategoriAdi}
                                      </Link>
                                    </OverlayTrigger>
                                  </li>
                                );
                              }
                            })
                            
                             
                          }
                        <li className="hesabim-li">
                          {
                            loggedIn ? 
                              <Link to="/account/hesabim">
                              <div className="header-icon-box">
                                <img src={girisYap} alt="giriş yap" />
                              </div>
                              <div className="header-name-box">                              
                                <span>Hesabım</span>
                              </div>
                            </Link>
                            :
                              <Link to="/account/login">
                                <div className="header-icon-box">
                                  <img src={girisYap} alt="giriş yap" />
                                </div>
                                <div className="header-name-box">
                                  <p>Giriş Yap</p>
                                  <span className="uye-ol-span" >veya üye ol</span>
                                </div>
                              </Link>
                          }
                         
                        </li>
                        <li className="sepatim-li">
                            <div className="header-icon-box">
                              <span className="basket-total-items">{props.cart.length}</span>
                              <img src={sepet} alt="sepet" />
                            </div>
                            <div className="header-name-box">
                                 <PopoverCart
                                    // CapaignProduct={cartCapaignProduct}
                                    cart={props.cart}
                                    addToCart={props.addToCart}
                                    removeToCart={props.removeToCart}
                                    removeItemToCart={props.removeItemToCart}
                                    />
                            </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </>
    );
  }

  else {
    return(
      <Container fluid className="mobile-menu-full-part">
        <div className="header-mobile-top-part">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <Link to="/sayfalar/siparis-sorgula">
              {orderTracking()}
              <span>Sipariş Takibi</span>
          </Link>
          <a href="tel:4441371">
            {phoneIcon()}
            <span>Ara Gelsin</span>
          </a>
          {loggedIn ? 
          <Link to="/account/hesabim">
            {loginIcon()}
            <span className="login-span">
                <span>Hesabım</span>
            </span>
          </Link> : <Link to="/account/login">
            {loginIcon()}
            <span className="login-span">
                <span>Giriş Yap</span>
                <span>veya üye ol</span>
            </span>
          </Link> }
          <Link to="/cart/index">
            <div className="header-icon-box">
              <span className="basket-total-items">{props.cart.length}</span>
              {cartIcon()}
              <p>Sepet</p>
          </div>
          </Link>
        </div>
        <MobileHeader />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.productList.cart,
  cartList: state.productList.cartList,
  productListFetchCount: state.productList.productListFetchCount,
  productListFetchState: state.productList.productListFetchState,
});

const mapDispatchToProps = dispatch => {
  return {
      addToCart: id => dispatch(addToCart(id)),
      removeToCart: id => dispatch(removeToCart(id)),
      removeItemToCart: id => dispatch(removeItemToCart(id)),
      campainCheckState: item => dispatch(campainCheckState(item)),
      dispatchFetchProductList: () => dispatch(fetchProductList())
  }
}

Header.propTypes = {
  addToCart: PropTypes.func.isRequired,
  removeToCart: PropTypes.func.isRequired,
  cart:PropTypes.array.isRequired,
  productListFetchState: PropTypes.string.isRequired,
  productListFetchCount: PropTypes.number.isRequired,
  dispatchFetchProductList: PropTypes.func.isRequired,
  };
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));

import React,{useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';
import { searchByOrderDetail } from '../../../api/service/cartServices';
import {OrderResult} from '../../../constans/svgExport';
import ReactGA from 'react-ga4';

function OrderCheckout() {

    const [orderInfo, setOrderInfo] = useState(null);

    useEffect(() => {
        document.body.classList.add('order-result');
        localStorage.removeItem('state');
        localStorage.removeItem('oderData');
        localStorage.removeItem('siparisId');
        const orderId = localStorage.getItem('orderId');
        if(orderId !== null){
            const getOrderInfo = async () => {
                const res = await searchByOrderDetail(orderId);
                if(!res.errStatus) {
                    setOrderInfo(res.data);
                }
            }
            getOrderInfo();
        }
    });

    useEffect(() => {
        
        ReactGA.initialize('G-6R4VNJYVZG');
        ReactGA.initialize('GTM-TNNVQV2'); 
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Ödeme Hatalı" });

        return () => {
            document.body.classList.remove('order-result');
            // localStorage.removeItem('orderResult');
            localStorage.removeItem('orderId');
            window.location.reload();
        };
    }, []);

    const orderId = localStorage.getItem('orderId');
   
        if(orderId !== null && orderInfo !== null) {
            const orderDate = new Date(orderInfo.siparisTarihi);
            const orderDateTime = orderDate.getDate().toString() + "-" + (orderDate.getMonth()+1).toString() +"-"+ orderDate.getFullYear();
            return(
                <>
                        <Container fluid className="order-result-full-part">
                            <Container className="order-result-normal-part">
                                <div className="order-grid-part">
                                    <div className="user-name">
                                        {OrderResult("#e13f3f")} 
                                        <div className="order-note">
                                        <h3> {orderInfo.user}</h3>
                                        <span>Sipariş Başarısız. </span>
                                        
                                        </div>
                                    
                                    </div>
                                    <div className="order-date">
                                    
                                        <span>Sipariş Tarihi: {orderDateTime}</span>
                                        <div className="siparis-no">
                                            <span>Sipariş Numarası</span>
                                            <span>{orderInfo.siparisNo}</span>
                                        </div>
                                    </div>
                                </div>
                            <div className="order-mesaj">
                                <p>
                                    Siparişinden hemen sonra; sahin724.com’da tarafına sipariş onayı ile ilgili bir e-posta gönderilir.</p>

                                    <p>Siparişinin durumunu ve tüm süreçlerini size özel safınızda bulunan Siparişlerim adımından takip edebilirsin.</p>

                            </div>
                            <div className="order-mail-note">
                                <p>Lütfen elektronik postanızı (e-mail) kontrol ediniz, üyelik ve sipariş bilgileriniz gönderilmiştir.</p>
                            </div>
                            </Container>
                        </Container>
                </>
            )
        }
        else {
            return("")
        }
   
}

export default OrderCheckout;